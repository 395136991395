import React from "react";
import ContractOutput from "../ContractOutput/ContractOutput";

interface ContractOutputsProps {
  abiOutputs: Array<any>;
}

const ContractOutputs: React.FC<ContractOutputsProps> = ({ abiOutputs }) => {
  return (
    <>
      {abiOutputs ? (
        abiOutputs.map((abiOutput: object, index: number) => {
          if (abiOutputs.length > 1) {
            // @ts-ignore
            return <ContractOutput key={index} abiOutput={abiOutput} />;
          } else {
            // @ts-ignore
            return (
              <span
                key={index}
                className="italic font-mono text-sm text-gray-500"
              >
                {/* @ts-ignore */}
                {abiOutput.type}
              </span>
            );
          }
        })
      ) : (
        <span className="italic font-mono text-sm text-gray-500">
          Does not return anything
        </span>
      )}
    </>
  );
};

export default ContractOutputs;
