import { motion } from "framer-motion";

import circle from "../../../assets/images/logoCircle.svg";

const FullpageLoader = () => {
  const circleVariants = {
    initial: {
      rotate: 0,
      scale: 0.5,
    },
    animate: {
      rotate: 360,
      scale: 1,
      transition: {
        rotate: {
          duration: 2,
          repeat: Infinity,
          ease: "linear",
        },
        scale: {
          duration: 0.8,
          type: "spring",
          bounce: 0.2,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        },
      },
    },
  };

  return (
    <section className="flex flex-col items-center justify-center gap-6 h-screen">
      <motion.img
        className="mini-circle relative z-30 scale-50 w-7 h-7"
        variants={circleVariants}
        initial="initial"
        animate="animate"
        src={circle}
        alt="Logo Small"
      />
      <h2 className="font-semibold text-blue-600">Loading...</h2>
    </section>
  );
};

export default FullpageLoader;
