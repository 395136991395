import { FC } from "react";
import { formatDuration, intervalToDuration } from "date-fns";

interface FormatTimePeriodProps {
  timePeriod: number;
}

const FormatTimePeriod: FC<FormatTimePeriodProps> = ({ timePeriod }) => {
  const formatTimePeriod = (seconds: number) => {
    const now = Date.now();
    const duration = intervalToDuration({
      start: now,
      end: now + seconds * 1000,
    });

    return formatDuration(duration);
  };

  return <span>{formatTimePeriod(timePeriod)}</span>;
};

export default FormatTimePeriod;
