// @ts-nocheck
import React, { FC, ReactNode } from "react";
import { Flowbite, Badge, CustomFlowbiteTheme } from "flowbite-react";
// import { copyToClipboard } from "../../../../helpers/copyToClipboard";

// import iconFire from "../../../../assets/icons/iconFire.svg";
import iconquestionMarkCircle from "../../../../assets/icons/iconquestionMarkCircle.svg";
import GasPercentage from "../../GasPercentage/GasPercentage";
import BlocksDetailsTimestamp from "../../../BlocksDetailsTimestamp/BlocksDetailsTimestamp";
import MonospacedDataBlock from "../../BlockchainData/MonospacedDataBlock/MonospacedDataBlock";

interface TabItem {
  steps: {
    title: string;
    content: ReactNode;
  }[];
}

const OverviewTab: FC = ({ data }) => {
  // const textToCopy = "Add the copied text here";
  const contentTabTheme: CustomFlowbiteTheme = {
    badge: {
      root: {
        base: "flex h-fit items-center justify-center gap-1 font-semibold",
        color: {
          success:
            "text-xs text-green-500 border-[1px] border-green-300 bg-green-100 px-2 rounded-lg",
        },
        size: {
          sm: "px-2",
        },
      },
    },
    progress: {
      base: "w-full bg-blue-200 rounded-full",
      color: {
        red: "bg-red-500",
        green: "bg-green-500",
      },
    },
  };

  const tabItems: TabItem[] = [
    {
      steps: [
        {
          title: "Block Height:",
          content: (
            <div className="col-span-2">
              <span className="font-sans text-be-navy-blue-700 break-all font-medium">
                {data.number.toString()}
              </span>
            </div>
          ),
        },
        {
          title: "Status:",
          content: (
            <div className="col-span-2 flex ">
              <div className="col-auto">
                <Badge color="success" size="sm">
                  Finalized
                </Badge>
              </div>
            </div>
          ),
        },
        {
          title: "Timestamp:",
          content: <BlocksDetailsTimestamp time={data.timestamp} />,
        },
        {
          title: "Transactions:",
          content: (
            <div className="col-span-2 font-medium">
              <span className="font-sans  text-blue-500 break-all">
                {data.transactions.length.toString()} transactions
              </span>
            </div>
          ),
        },
      ],
    },
    {
      steps: [
        {
          title: "Size:",
          content: (
            <div className="col-span-2 text-be-navy-blue-700 flex gap-2 font-medium items-center">
              <span className="break-all">{data.size.toString()} bytes</span>
            </div>
          ),
        },
        {
          title: "Gas Used:",
          content: (
            <GasPercentage
              color="red"
              used={data.gasUsed}
              max={data.gasLimit}
            />
          ),
        },
        {
          title: "Gas Limit:",
          content: (
            <div className="col-span-2 font-medium">
              <span className="break-all">
                {data.gasLimit.toLocaleString("en-US").toString()}
              </span>
            </div>
          ),
        },
        {
          title: "Extra Data:",
          content: (
            <div className="col-span-2 font-medium">
              <MonospacedDataBlock contents={data.extraData} extended={true} />
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <Flowbite theme={{ theme: contentTabTheme }}>
      <div className="bg-white border border-gray-300 rounded-xl w-full h-full">
        {tabItems.map((item, itemIndex) => (
          <div
            key={itemIndex}
            className={`p-4 grid grid-cols-1 gap-y-8 py-8 px-6 ${
              itemIndex === tabItems.length - 1
                ? ""
                : "border-b border-gray-300"
            }`}
          >
            {item.steps.map((step, index) => (
              <div
                key={index}
                className="flex flex-col md:grid md:grid-cols-3 gap-2 items-start justify-between"
              >
                <div className="flex items-center space-x-2 col-span-1">
                  <img
                    src={iconquestionMarkCircle}
                    alt="Question Mark Circle icon"
                  />
                  <span className="text-be-navy-blue-300 font-sans font-medium whitespace-nowrap">
                    {step.title}
                  </span>
                </div>
                <span className="font-medium w-full col-span-2">
                  {" "}
                  {step.content}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Flowbite>
  );
};

export default OverviewTab;
