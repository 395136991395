import { FC } from "react";
import { Table } from "flowbite-react";

import InternalLink from "../../common/InternalLink/InternalLink";
import {
  formatMobileTimestamp,
  formatTimestamp,
} from "../../../helpers/formatTimestamp";

interface TransactionsTableRowProps {
  txn: any;
}

const TransactionsTableRow: FC<TransactionsTableRowProps> = ({ txn }) => {
  return (
    <Table.Row
      className={`bg-white border-b dark:bg-gray-800 w-full space-x-3`}
    >
      <Table.Cell className="text-blue-500 font-bold min-w-48 md:min-w-0">
        <InternalLink blockchainRef={txn.tx} type="tx" clipboard={false} />
      </Table.Cell>
      <Table.Cell className="text-blue-500 font-medium min-w-20 md:min-w-0">
        <InternalLink
          blockchainRef={txn.blk.toString()}
          type="block"
          clipboard={false}
        />
      </Table.Cell>
      <Table.Cell className="text-be-navy-blue-700 font-medium min-w-28 md:min-w-0">
        <span className="hidden md:block">
          {formatTimestamp(parseInt(txn.data.timestamp, 16))}
        </span>
        <span className="block md:hidden">
          {formatMobileTimestamp(parseInt(txn.data.timestamp, 16))}
        </span>
      </Table.Cell>
      <Table.Cell className="text-blue-500 min-w-48 md:min-w-0">
        <InternalLink
          blockchainRef={txn.data.from}
          type="address"
          clipboard={false}
        />
      </Table.Cell>
      <Table.Cell className="min-w-48 md:min-w-0">
        {txn.data.to ? (
          <InternalLink
            blockchainRef={txn.data.to}
            type="address"
            clipboard={false}
          />
        ) : (
          "Contract Creation"
        )}
      </Table.Cell>
      <Table.Cell className="text-be-navy-blue-700 font-medium min-w-20 md:min-w-0">
        {(parseInt(txn.data.value, 16) / 1000000000000000000).toString()} JSM
      </Table.Cell>
      <Table.Cell className="text-be-navy-blue-700 font-medium">
        {parseInt(txn.data.gas, 16).toString()}{" "}
      </Table.Cell>
    </Table.Row>
  );
};

export default TransactionsTableRow;
