import { http, createConfig } from "wagmi";
import { metaMask } from "wagmi/connectors";
import { joseon } from "./joseon";

export const config = createConfig({
  chains: [joseon],
  connectors: [metaMask()],
  transports: {
    [joseon.id]: http(),
  },
});
