import React, { useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useConfig } from "wagmi";
import { getClient } from "@wagmi/core";
import { getContract } from "viem";

import GlobalClient from "../../recoil/GlobalClient";
import ContractFunction from "../ContractFunction/ContractFunction";

const inferType = (stateMutability: string) => {
  let inferredType = "read";

  switch (stateMutability) {
    case "pure":
      inferredType = "read";
      break;
    case "view":
      inferredType = "read";
      break;
    case "nonpayable":
      inferredType = "write";
      break;
    case "payable":
      inferredType = "write";
      break;
  }

  return inferredType;
};

// @ts-ignore
const ContractTab = ({ contract }) => {
  const config = useConfig();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const client = getClient(config);
  const qClient = useRecoilValue(GlobalClient);

  const handleToggle = (index: number) => {
    setOpenIndex((prev) => (prev === index ? null : index));
  };

  const execute = useCallback(
    async (request: object) => {
      var requestContract = contract;

      requestContract = getContract({
        client: qClient ? qClient : client,
        // @ts-ignore
        address: contract.address,
        abi: contract.abi,
      });

      try {
        // @ts-ignore
        var result = await requestContract[inferType(request.stateMutability)][
          // @ts-ignore
          request.functionName
          // @ts-ignore
        ](request.args);
      } catch (e: any) {}

      return String(result);
    },
    [contract, qClient, client],
  );

  return (
    <div className="flex flex-col md:flex-row gap-4 bg-white border-[1px] shadow-[0_5px_8px_-3px_rgba(0,0,0,0.04)] border-be-navy-blue-100 p-6 space-y-3 rounded-xl">
      <div className="w-full space-y-4">
        {contract.abi.map((abiEntry: any, index: number) => (
          <ContractFunction
            key={index}
            abiEntry={abiEntry}
            onToggle={() => handleToggle(index)}
            isOpen={openIndex === index}
            execute={execute}
          />
        ))}
      </div>
    </div>
  );
};

export default ContractTab;
