import { FC } from "react";
import { Table } from "flowbite-react";

import InternalLink from "../../common/InternalLink/InternalLink";

// @ts-ignore
const BlockTransactionsTableRow: FC = ({ tx }) => {
  return (
    <Table.Row className="bg-white border-b dark:bg-gray-800 w-full space-x-3">
      <Table.Cell className="text-blue-500 font-bold min-w-48 md:min-w-0">
        <InternalLink blockchainRef={tx.hash} type="tx" />
      </Table.Cell>
      <Table.Cell>
        <div className="flex flex-col min-w-40 md:min-w-0">
          <div className="flex gap-1">
            <InternalLink blockchainRef={tx.from} type="address" />
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex flex-col min-w-40 md:min-w-0">
          <div className="flex gap-1">
            {tx.to ? (
              <InternalLink blockchainRef={tx.to} type="address" />
            ) : (
              "Contract Creation"
            )}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>{parseInt(tx.value, 16) / 1000000000000000000}</Table.Cell>
      <Table.Cell>{parseInt(tx.gas) * parseInt(tx.gasPrice)}</Table.Cell>
    </Table.Row>
  );
};

export default BlockTransactionsTableRow;
