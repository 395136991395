import { Table } from "flowbite-react";

import BlockTransactionsTableRow from "../../../Rows/BlockTransactionsTableRow/BlockTransactionsTableRow";
import HomeTable from "../../HomeTable/HomeTable";
import icon_sort_ascending from "../../../../assets/icons/icon_sort_ascending.svg";

// @ts-ignore
const BlockTransactionsTab = ({ data }) => {
  if (data.length < 1) return "No txs";

  return (
    <div className="bg-white border-2 border-gray-200 rounded-xl p-6 px-4 space-y-6 md:space-y-12">
      <div className="flex flex-col-reverse md:flex-row gap-4 md:justify-between items-start md:items-center">
        <div className="flex  items-center space-x-0.5 md:space-x-1">
          <img
            src={icon_sort_ascending}
            alt="icon sort-ascending"
            loading="lazy"
          />
          <div>
            <div className="font-sans font-medium text-be-navy-blue-700 flex items-center whitespace-nowrap">
              {data.length} transaction(s) in this block
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <HomeTable>
          <Table.Head>
            <Table.HeadCell className="!bg-white">Txn Hash</Table.HeadCell>
            <Table.HeadCell className="!bg-white">From</Table.HeadCell>
            <Table.HeadCell className="!bg-white">To</Table.HeadCell>
            <Table.HeadCell className="!bg-white">Value</Table.HeadCell>
            <Table.HeadCell className="whitespace-nowrap !bg-white">
              Txn Fee
            </Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {/** @ts-ignore **/}
            {data.map((tx, index) => (
              // @ts-ignore
              <BlockTransactionsTableRow key={index} tx={tx} />
            ))}
          </Table.Body>
        </HomeTable>
      </div>
    </div>
  );
};

export default BlockTransactionsTab;
