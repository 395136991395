import React, { FC, useState, useEffect, useRef } from "react";
import { Table } from "flowbite-react";
import { useConfig } from "wagmi";
import { getClient } from "@wagmi/core";

// import MetricsCard from "../../features/common/MetricsCard/MetricsCard";
// import Paging from "../../features/common/Paging/Paging";
import BlockTableRow from "../../features/Rows/BlockTableRow/BlockTableRow";
import InfiniteScroll from "../../features/common/InfiniteScroll/InfiniteScroll";
// import DisplayRows from "../../features/common/HomeTable/DisplayTable/DisplayRows";
import FullpageLoader from "../../features/common/FullpageLoader/FullpageLoader";

// const metricsData = [
//   {
//     metric: [
//       {
//         contents: [
//           {
//             label: "NETWORK UTILIZATION (24H)",
//             value: "51.3%",
//           },
//         ],
//         icon: (
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             strokeWidth={1}
//             stroke="currentColor"
//             className="w-6 h-6 fill-white stroke-blue-700"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
//             />
//           </svg>
//         ),
//       },
//     ],
//   },
//   {
//     metric: [
//       {
//         contents: [
//           {
//             label: "LAST SAFE BLOCK",
//             value: "17412482",
//           },
//         ],
//         icon: (
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             strokeWidth={1}
//             stroke="currentColor"
//             className="w-6 h-6 fill-white stroke-blue-700"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
//             />
//           </svg>
//         ),
//       },
//     ],
//   },
//   {
//     metric: [
//       {
//         contents: [
//           {
//             label: "BURNT FEES",
//             value: "17412482",
//           },
//         ],
//         icon: (
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             strokeWidth={1}
//             stroke="currentColor"
//             className="w-6 h-6 fill-white stroke-blue-700"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z"
//             />
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z"
//             />
//           </svg>
//         ),
//       },
//     ],
//   },
// ];

const Blocks: FC = () => {
  const config = useConfig();
  const client = getClient(config);
  const [latestBlocksData, setLatestBlocksData] = useState([]);
  const [latestBlocksLoading, setLatestBlocksLoading] = useState(true);
  const continueKey = useRef("");

  const fetchLatestBlocks = async () => {
    const params: Array<string> = [];

    if (continueKey.current) params.push(continueKey.current);

    const latestBlocks = await client.request({
      // @ts-ignore
      method: "modchain_latestBlocks",
      // @ts-ignore
      params: params,
    });

    return latestBlocks;
  };

  useEffect(() => {
    if (latestBlocksData.length === 0) {
      fetchLatestBlocks().then((blocks) => {
        // @ts-ignore
        if (blocks !== null && blocks.results) {
          // @ts-ignore
          setLatestBlocksData(blocks.results);
          setLatestBlocksLoading(false);
          // @ts-ignore
          continueKey.current = blocks.continueKey;
        }
      });
    }
  }, []); // eslint-disable-line

  const handleShowMore = async () => {
    return await fetchLatestBlocks().then((blocks) => {
      setLatestBlocksData((lbd) => {
        // @ts-ignore
        return lbd.concat(blocks.results);
      });

      // @ts-ignore
      continueKey.current = blocks.continueKey;

      // @ts-ignore
      return blocks.continueKey;
    });
  };

  if (latestBlocksLoading) return <FullpageLoader />;

  return (
    <section className="bg-be-navy-blue-50 min-h-screen pb-6 md:py-8 w-full pt-14 md:pt-0">
      <div className="mx-4 md:mx-6 space-y-6">
        <div className="border-b py-4">
          <p className="font-bold text-xl text-be-navy-blue-700">
            Blocks History
          </p>
        </div>
        <p className="text-be-navy-blue-500">All blocks since inception.</p>
        {/* <div className="w-full md:w-8/12">
          <MetricsCard
            metrics={metricsData}
            gridColumn
            maxwidth="max-w-[1000px]"
          />
        </div> */}

        <div className="bg-white border rounded-xl px-4 py-6 md:p-6 space-y-6 md:space-y-12 w-full">
          <div className="flex md:flex-row flex-col justify-between items-center space-y-4 md:space-y-0">
            <div className="space-y-2 col-span-1">
              <p className="text-lg font-bold text-be-navy-blue-700">
                Total of{" "}
                {
                  // @ts-ignore
                  parseInt(latestBlocksData[0].number, 16).toLocaleString(
                    "en-US",
                  )
                }
                &nbsp;blocks
              </p>
            </div>
          </div>
          <div className="overflow-x-auto">
            <Table hoverable>
              <Table.Head>
                <Table.HeadCell className="!bg-white">Block</Table.HeadCell>
                <Table.HeadCell className=" !bg-white">Age</Table.HeadCell>
                <Table.HeadCell className="!bg-white">Txs Count</Table.HeadCell>
                <Table.HeadCell className="!bg-white">Validator</Table.HeadCell>
                <Table.HeadCell className="whitespace-nowrap !bg-white">
                  Gas Used
                </Table.HeadCell>
                <Table.HeadCell className="whitespace-nowrap ml-[200px] !bg-white">
                  Gas Limit
                </Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {latestBlocksData.map((data, index) => {
                  return <BlockTableRow key={index} data={data} />;
                })}
                <InfiniteScroll fetchData={() => handleShowMore()} />
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blocks;
