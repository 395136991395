import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useConfig } from "wagmi";
import { getClient } from "@wagmi/core";
import { getContract, decodeEventLog, formatUnits } from "viem";
// @ts-ignore
import { rest } from "@karpeleslab/klbfw";
import { Table } from "flowbite-react";

import { joseon } from "../../wagmi/joseon";
import Wrapper from "../../features/common/Wrapper/Wrapper";
import FullpageLoader from "../../features/common/FullpageLoader/FullpageLoader";
import DAOTableRow from "../../features/DAOTableRow/DAOTableRow";
import Dropdown from "../../features/common/Dropdown/Dropdown";
import DAOProjectInfo from "../../features/DAOProjectInfo/DAOProjectInfo";
import DAOcard from "../../features/common/DAOcard/DAOcard";

import { fetchContractInfo, fetchContractLogs } from "../../api/contractsApi";

import {
  DocumentTextIcon,
  PuzzlePieceIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import Container from "../../features/common/Container/Container";

const DAOProject: FC = () => {
  const params = useParams();
  const config = useConfig();
  const client = getClient(config);
  const [isLoading, setIsLoading] = useState(true);
  const [contractInfo, setContractInfo] = useState({
    corporationId: "",
    name: "",
    url: "",
    governorAddress: "",
    tokenAddress: "",
    tokenSupply: "",
    quorum: "",
    votingDelay: "",
    votingPeriod: "",
  });
  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    // @ts-ignore
    fetchContractInfo(client, params.contractAddress).then((result: any) => {
      setProposals([]);

      if (result.abi) {
        const daoContract = getContract({
          client: client,
          // @ts-ignore
          address: params.contractAddress,
          abi: result.abi,
        });

        // @ts-ignore
        fetchContractLogs(client, params.contractAddress, "7d84a626").then(
          (logs: any) => {
            logs.results.map((data: any, index: number) => {
              const decodedLog = decodeEventLog({
                abi: [data.abi],
                data: data.log.data,
                topics: data.log.topics,
              });

              daoContract.read
                // @ts-expect-error
                .proposalVotes([decodedLog.args.proposalId])
                .then((votes: any) => {
                  const parsedVotes = {
                    for: formatUnits(votes[1], joseon.nativeCurrency.decimals),
                    against: formatUnits(
                      votes[0],
                      joseon.nativeCurrency.decimals,
                    ),
                    abstain: formatUnits(
                      votes[2],
                      joseon.nativeCurrency.decimals,
                    ),
                    total: formatUnits(
                      votes[0] + votes[1] + votes[2],
                      joseon.nativeCurrency.decimals,
                    ),
                  };

                  // @ts-ignore
                  const parsedTitle = decodedLog.args.description
                    ? // @ts-ignore
                      JSON.parse(decodedLog.args.description).title
                    : "Untitled";

                  // @ts-expect-error
                  setProposals((proposals) => {
                    const updatedProposals = [
                      ...proposals,
                      {
                        // @ts-ignore
                        tx: data.tx,
                        governorAddress: params.contractAddress,
                        title: parsedTitle,
                        createdOn: parseInt(data.data.timestamp, 16),
                        // @ts-ignore
                        voteStart: Number(decodedLog.args.voteStart),
                        // @ts-ignore
                        voteEnd: Number(decodedLog.args.voteEnd),
                        status: "Active",
                        voteStatus: "For",
                        votes: parsedVotes,
                      },
                    ];

                    updatedProposals.sort((a, b) => b.createdOn - a.createdOn);

                    return updatedProposals;
                  });
                });
              return true;
            });
          },
        );

        // @ts-ignore
        daoContract.read.name([]).then((name: string) => {
          rest("Joseon/Corporation/" + name, "GET", {}).then((res: any) => {
            setContractInfo((ci) => {
              return {
                ...ci,
                corporationId: name,
                name: res.data.Name,
                url: res.data.Url,
                governorAddress: daoContract.address,
                // correct this to get on-chain
                tokenAddress: res.data.Shares_EVM_Contract?.Contract,
                tokenSupply: res.data.Shares,
                quorum: res.data.Quorum,
                votingDelay: res.data.Voting_Delay,
                votingPeriod: res.data.Voting_Period,
              };
            });
          });

          setIsLoading(false);
        });
      }
    });
  }, [client, params.contractAddress]);

  if (isLoading || !contractInfo) return <FullpageLoader />;

  return (
    <Container className="pt-16 md:pt-28">
      <div className="container space-y-3 md:space-y-6">
        <div className="drop-shadow">
          <DAOProjectInfo info={contractInfo} />
        </div>
        <div>
          <div className="hidden flex justify-between gap-4 mb-6 flex-wrap lg:flex-nowrap">
            <DAOcard
              icon={<DocumentTextIcon className="h-6 w-6 text-blue-500" />}
              title="Proposals"
              value="11"
            />
            <DAOcard
              icon={<PuzzlePieceIcon className="h-6 w-6 text-blue-500" />}
              title="Holders"
              value="44K"
            />
            <DAOcard
              icon={<UserGroupIcon className="h-6 w-6 text-blue-500" />}
              title="Voters"
              value="64"
            />
          </div>
          <Wrapper>
            <div className="flex justify-between items-center pt-3 ">
              <h3 className="text-xl text-be-navy-blue-700 font-bold pl-3">
                Proposals ({proposals.length})
              </h3>
              <div className="hidden relative inline-block">
                <Dropdown
                  label="Filter"
                  color="grey"
                  size="sm"
                  alignment="right"
                  checkboxVisibility
                  options={[
                    [
                      {
                        value: "Active",
                      },
                      {
                        value: "Pending Execution",
                      },
                      {
                        value: "Executed",
                      },
                      {
                        value: "Defeated",
                      },
                    ],
                  ]}
                />
              </div>
            </div>
            <div className="overflow-x-auto">
              <Table hoverable>
                <Table.Head>
                  <Table.HeadCell className="whitespace-nowrap">
                    Proposal Title
                  </Table.HeadCell>
                  <Table.HeadCell className="whitespace-nowrap">
                    Vote start
                  </Table.HeadCell>
                  <Table.HeadCell className="whitespace-nowrap">
                    Vote end
                  </Table.HeadCell>
                  <Table.HeadCell className="whitespace-nowrap">
                    Votes (For/Against/Abstain)
                  </Table.HeadCell>
                  <Table.HeadCell className="whitespace-nowrap">
                    Total Votes
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body>
                  {proposals.map((proposal: any, index: number) => {
                    // @ts-ignore
                    return <DAOTableRow key={index} proposal={proposal} />;
                  })}
                </Table.Body>
              </Table>
            </div>
          </Wrapper>
        </div>
      </div>
    </Container>
  );
};

export default DAOProject;
