import React from "react";
import { Table } from "flowbite-react";

import {
  formatMobileTimestamp,
  formatTimestamp,
} from "../../../helpers/formatTimestamp";
import InternalLink from "../../common/InternalLink/InternalLink";

import iconSwitchHorizontal from "../../../assets/icons/iconSwitchHorizontal.svg";

interface MiniTransactionRowProps {
  txsData: {
    blk: number;
    tx: string;
    data: {
      from: string;
      gas: string;
      gasPrice: string;
      timestamp: string;
      to: string;
      value: string;
    };
  };
}

const MiniTransactionRow: React.FC<MiniTransactionRowProps> = ({ txsData }) => {
  return (
    <Table.Row className="border-b border-be-navy-blue-100 flex md:justify-between">
      <Table.Cell className="flex pr-2 md:pr-4 pl-0 min-w-36 md:w-fit">
        <div className="flex flex-row items-center gap-2 md:gap-3">
          <div className="bg-be-navy-blue-50 rounded-lg h-[38px] w-[38px] p-2">
            <img
              src={iconSwitchHorizontal}
              alt="cube icon"
              className="w-full h-full"
            />
          </div>
          <div className="flex flex-col gap-1 text-sm">
            <InternalLink
              blockchainRef={txsData.tx}
              type="tx"
              clipboard={false}
            />
            <span className="text-be-navy-blue-700 font-medium">
              <span className="hidden md:block">
                {formatTimestamp(txsData.data.timestamp)}
              </span>
              <span className="block md:hidden">
                {formatMobileTimestamp(txsData.data.timestamp)}
              </span>
            </span>
          </div>
        </div>
      </Table.Cell>
      <Table.Cell className="md:block pl-2 md:pl-4 pr-0 flex flex-col md:flex-row justify-between w-full md:w-fit">
        <div>
          <span className="font-medium ">From </span>
          <InternalLink
            blockchainRef={txsData.data.from}
            type="address"
            clipboard={false}
          />
        </div>
        <div className="flex gap-1 font-medium  ">
          <span className="font-medium ">To </span>
          {txsData.data.to ? (
            <InternalLink
              blockchainRef={txsData.data.to}
              type="address"
              clipboard={false}
            />
          ) : (
            "Contract creation"
          )}
        </div>
        <div className="rounded-lg whitespace-nowrap border border-be-navy-blue-300 text-be-navy-blue-700 font-bold text-[10px] md:text-xs px-1 md:px-3 py-1  md:py-2 text-center">
          {(parseInt(txsData.data.value, 16) / 1000000000000000000).toString()}{" "}
          JSM
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default MiniTransactionRow;
