import React, { FC } from "react";

import InternalLink from "../common/InternalLink/InternalLink";
import Dropdown from "../common/Dropdown/Dropdown";
import Button from "../common/Button/Button";
import TextInput from "../common/TextInput/TextInput";
import BorderContainer from "../common/BorderContainer/BorderContainer";

import { ArrowRightIcon } from "@heroicons/react/24/outline";

// @ts-ignore
const TransferTokens: FC = ({ action, callback, contractInfo }) => {
  const handleValueChange = (e: any) => {
    const newAction = action;
    newAction.value = e.target.value;
    callback(newAction);
  };

  const handleTargetChange = (e: any) => {
    const newAction = action;
    newAction.target = e.target.value;
    callback(newAction);
  };

  const lock = () => {
    const newAction = action;
    newAction.lock = !newAction.lock;
    callback(newAction);
  };

  return (
    <div className="space-y-9">
      <div className="space-y-9">
        <TextInput
          label="Target address"
          placeholder="0x..."
          onChange={handleTargetChange}
          value={action.target}
          disabled={action.lock}
        />
        <div className="space-y-4">
          <h4 className="font-bold text-be-navy-blue-700">Value</h4>
          <BorderContainer className="flex justify-between">
            <div className="relative flex flex-col items-start gap-6 ">
              <Dropdown
                label="JSM"
                size="sm"
                color="grey"
                alignment="left"
                options={[
                  [
                    {
                      value: "Joseon Mun (JSM)",
                    },
                  ],
                ]}
              />
            </div>
            <div className="flex flex-col justify-end gap-4">
              <span className="text-3xl text-be-navy-blue-700 font-medium ml-auto"></span>
              <TextInput
                label=""
                onChange={handleValueChange}
                value={action.value}
                disabled={action.lock}
              />
              <span className="text-be-navy-blue-500 text-xs tracking-wider	uppercase">
                amount
              </span>
            </div>
          </BorderContainer>
          <BorderContainer className="flex justify-center">
            <div className="flex flex-col justify-center items-center gap-4">
              <span className="text-xs tracking-widest	 text-be-navy-blue-700 font-medium uppercase">
                Transfer
              </span>
              <span className="text-be-navy-blue-700 font-bold text-3xl">
                {action.value} JSM
              </span>
              <div className="flex gap-4 items-center">
                {contractInfo.name}
                <InternalLink
                  blockchainRef={contractInfo.governorAddress}
                  type="address"
                />
                <ArrowRightIcon className="h-6 w-6 text-be-navy-blue-700 " />
                <InternalLink blockchainRef={action.target} type="address" />
              </div>
            </div>
          </BorderContainer>
          <div className="flex justify-end">
            <Button
              size="sm"
              color="white"
              label={action.lock ? "Unlock" : "Lock"}
              onClick={lock}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferTokens;
