import { FC, ReactNode, useRef, useState } from "react";
import { Tabs, CustomFlowbiteTheme, TabsRef } from "flowbite-react";

interface TabItem {
  title: string;
  content: ReactNode;
}

interface BlockTabsProps {
  tabItems: TabItem[];
}

export const tabsTheme: CustomFlowbiteTheme["tabs"] = {
  tablist: {
    base: "flex items-center  gap-8 text-center overflow-hidden",
    styles: {
      default: "border-b border-gray-200 ",
    },
    tabitem: {
      base: "flex items-center justify-center max-w-[165px] w-full py-4 rounded-t-lg text-xs md:text-sm font-medium first:ml-0 focus:ring-0 focus:ring-transparent focus:outline-none ",
      styles: {
        default: {
          base: "rounded-t-lg",
          active: {
            on: "font-bold text-blue-500 border-b-[2px] border-transparent",
            off: "font-bold text-gray-500 border-b-[2px] border-transparent",
          },
        },
      },
    },
  },
};

const BeTab: FC<BlockTabsProps> = ({ tabItems }) => {
  const tabsRef = useRef<TabsRef>(null);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Tabs
      theme={tabsTheme}
      className="w-full"
      ref={tabsRef}
      onActiveTabChange={(tab) => setActiveTab(tab)}
    >
      {tabItems.map((item, index) => (
        <Tabs.Item
          key={index}
          active={index === 0}
          title={
            <h3 key={index} className="relative">
              {item.title}
              <div
                className={`absolute -bottom-5 -left-10 h-1 w-[calc(100%+5rem)] transition-transform duration-150 ${
                  activeTab === index
                    ? "bg-blue-500 transform translate-x-0"
                    : activeTab < index
                    ? "bg-transparent -translate-x-full origin-left"
                    : "bg-transparent translate-x-full origin-right"
                }`}
              />
            </h3>
          }
        >
          {item.content}
        </Tabs.Item>
      ))}
    </Tabs>
  );
};

export default BeTab;
