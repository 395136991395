import { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Flowbite } from "flowbite-react";
import { useSetRecoilState } from "recoil";
import {
  getClient,
  getWalletClient,
  watchConnections,
  getAccount,
} from "@wagmi/core";

import { config } from "../../../../wagmi/config";
import GlobalClient from "../../../../recoil/GlobalClient";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

import globalTheme from "../../../../theme/theme";

interface PageLayoutProps {}

const PageLayout: FC<PageLayoutProps> = () => {
  const setGlobalClient = useSetRecoilState(GlobalClient);
  const { address } = getAccount(config);

  useEffect(() => {
    if (address === undefined) {
      // @ts-ignore
      setGlobalClient(getClient(config));
    }
  }, [address, setGlobalClient]);

  watchConnections(config, {
    onChange(data) {
      if (data.length > 0) {
        getWalletClient(config, {
          connector: data[0]?.connector,
          chainId: config.chains[0].id,
        }).then((walletClient: any) => {
          setGlobalClient(walletClient);
        });
      }
    },
  });

  return (
    <Flowbite theme={{ theme: globalTheme }}>
      <div className="min-h-screen flex flex-col">
        <Header />
        <div>
          <Outlet />
        </div>
        <div className="mt-auto">
          <Footer />
        </div>
      </div>
    </Flowbite>
  );
};

export default PageLayout;
