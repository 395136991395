import { useState, useEffect } from "react";
import {
  CheckCircleIcon,
  MinusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Label, Radio } from "flowbite-react";

// @ts-expect-error
const DAOVoteChoices = ({ support, callback }) => {
  const [supportState, setSupportState] = useState(support);

  const handleSupportChange = (e: any) => {
    setSupportState(e.target.value);
  };

  useEffect(() => {
    callback(supportState);
  }, [supportState, callback]);

  // @TODO ATTENTION: Currently hardcoded, should feed from https://docs.openzeppelin.com/contracts/4.x/api/governance#IGovernor-COUNTING_MODE--
  return (
    <fieldset className="flex flex-col gap-4 mb-4">
      <legend className="mb-4 font-bold text-be-navy-blue-700">
        Cast your vote
      </legend>
      <div className="flex justify-between items-center border rounded-lg py-4 px-6 w-full">
        <Label htmlFor="vote_for" className="flex items-center gap-2">
          <CheckCircleIcon className="w-6 h-6 fill-green-100 stroke-green-500" />
          <span className="font-bold text-base leading-[17.6px] text-be-navy-blue-700">
            For
          </span>
        </Label>
        <Radio
          className="outline-none focus:ring-0 focus:ring-be-transparent"
          id="vote_for"
          name="vote"
          value="1"
          onChange={handleSupportChange}
          checked={supportState === "1" ? true : false}
        />
      </div>
      <div className="flex justify-between items-center border rounded-lg py-4 px-6 w-full">
        <Label htmlFor="vote_against" className="flex items-center gap-2">
          <XCircleIcon className="w-6 h-6 fill-red-100 stroke-red-500" />
          <span className="font-bold text-base leading-[17.6px] text-be-navy-blue-700">
            Against
          </span>
        </Label>
        <Radio
          id="vote_against"
          className="outline-none focus:ring-0 focus:ring-be-transparent"
          name="vote"
          value="0"
          onChange={handleSupportChange}
          checked={supportState === "0" ? true : false}
        />
      </div>
      <div className="flex justify-between items-center border rounded-lg py-4 px-6 w-full">
        <Label htmlFor="vote_abstain" className="flex items-center gap-2">
          <MinusCircleIcon className="w-6 h-6 fill-be-navy-blue-100 stroke-be-navy-blue-500" />
          <span className="font-bold text-base leading-[17.6px] text-be-navy-blue-700">
            Abstain
          </span>
        </Label>
        <Radio
          id="vote_abstain"
          className="outline-none focus:ring-0 focus:ring-be-transparent"
          name="vote"
          value="2"
          onChange={handleSupportChange}
          checked={supportState === "2" ? true : false}
        />
      </div>
    </fieldset>
  );
};

export default DAOVoteChoices;
