import { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useConfig } from "wagmi";
import { getClient } from "@wagmi/core";

import TransactionOverviewTab from "../../features/common/TransactionTabs/TransactionOverviewTab/TransactionOverviewTab";
import TransactionLogsTab from "../../features/common/TransactionTabs/TransactionLogsTab/TransactionLogsTab";
import BeTab from "../../features/common/BlockTabs/BlockTabs";
import FullpageLoader from "../../features/common/FullpageLoader/FullpageLoader";
import { fetchTransactionInfo } from "../../api/contractsApi";

const TransactionDetails: FC = () => {
  // viem/wagmi setup
  const params = useParams();
  const config = useConfig();
  const client = getClient(config);

  // state base
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[]>([]);

  // state remote datas
  const [txInfo, setTxInfo] = useState<null | Record<string, unknown>>(null);

  // state fns
  const resetState = () => {
    setIsLoading(true);
    setErrors([]);
    setTxInfo(null);
  };

  const addError = (error: string) => { // eslint-disable-line
    setErrors((cur) => [...cur, error]);
  };

  const fetchDatas = async (txHash: string) => {
    const txInfo = await fetchTransactionInfo(client, txHash);

    setTxInfo(txInfo);
    setIsLoading(false);
  };

  useEffect(() => {
    if (params.txHash === undefined) return;

    resetState();
    fetchDatas(params.txHash);
  }, [params.txHash]); // eslint-disable-line

  if (isLoading) {
    return <FullpageLoader />;
  } else {
    const tabItems = [
      {
        title: "Overview",
        // @ts-ignore
        content: (
          // @ts-expect-error
          <TransactionOverviewTab data={txInfo.tx} block={txInfo.block} />
        ),
      },
    ];

    // @ts-ignore
    if (txInfo.receipt.logs.length > 0) {
      tabItems.push({
        title: "Logs",
        content: (
          <TransactionLogsTab
            // @ts-ignore
            receipt={txInfo.receipt}
          />
        ),
      });
    }

    if (errors.length > 0) return;

    return (
      <section className="overflow-x-auto bg-be-navy-blue-50">
        <div className="container space-y-3 py-8 h-full">
          <div className="border-b py-4 flex items-center md:justify-normal justify-between space-x-4">
            <p className="font-bold text-xl text-be-navy-blue-700 ">
              Transaction Details
            </p>
          </div>
          <div>
            <BeTab tabItems={tabItems} />
          </div>
        </div>
      </section>
    );
  }
};

export default TransactionDetails;
