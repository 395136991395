import {
  createBrowserRouter,
  Route,
  Routes,
  RouterProvider,
} from "react-router-dom";
import { RecoilRoot } from "recoil";
// @ts-ignore
import { getPrefix } from "@karpeleslab/klbfw";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./wagmi/config";

import PageLayout from "./features/common/layout/PageLayout/PageLayout";
import Home from "./pages/Home/Home";
import BlockDetails from "./pages/BlockDetails/BlockDetails";
import Blocks from "./pages/Blocks/Blocks";
import Transactions from "./pages/Transactions/Transactions";
import AddressDetails from "./pages/AddressDetails/AddressDetails";
import TransactionDetails from "./pages/TransactionDetails/TransactionDetails";
//import PendingTransactions from "./pages/PendingTransactions/PendingTransactions";
//import DAO from "./pages/DAO/DAO";
import DAOProject from "./pages/DAOProject/DAOProject";
import DAOProposal from "./pages/DAOProposal/DAOProposal";
import CreateProposal from "./pages/CreateProposal/CreateProposal";

const router = createBrowserRouter([{ path: "*", Component: Root }], {
  basename: getPrefix(),
});

export default function App() {
  return <RouterProvider router={router} />;
}

const queryClient = new QueryClient();

function Root() {
  return (
    <RecoilRoot>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<PageLayout />}>
              <Route index element={<Home />} />
              <Route path="/block/:blockNumber" element={<BlockDetails />} />
              <Route path="/blocks" element={<Blocks />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/address/:hash" element={<AddressDetails />} />
              <Route path="/tx/:txHash" element={<TransactionDetails />} />
              <Route path="/dao/:contractAddress" element={<DAOProject />} />
              <Route
                path="/dao/:contractAddress/propose"
                element={<CreateProposal />}
              />
              <Route
                path="/dao/:contractAddress/proposal/:proposalTx"
                element={<DAOProposal />}
              />
              {/**
                <Route
                  path="/pending-transactions"
                  element={<PendingTransactions />}
                />
                <Route path="/dao" element={<DAO />} />**/}
            </Route>
          </Routes>
        </QueryClientProvider>
      </WagmiProvider>
    </RecoilRoot>
  );
}
