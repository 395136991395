import { useDispatch } from "react-redux";
import Link from "../Link/Link";
import { closeNavBar } from "../../../redux/reducers/toggleSlice";
import { Tooltip } from "flowbite-react";
import MenuDropdown from "../MenuDropdown/MenuDropdown";

// @ts-ignore
const NavLinks = ({ daoModalCallback }) => {
  const location = window.location;
  const dispatch = useDispatch();
  const pages = [
    {
      label: (
        <span
          onClick={() => dispatch(closeNavBar())}
          className={`text-sm  block md:ml-0  ${
            location.pathname === "/" ? " text-red-500 font-bold" : ""
          } `}
        >
          Home
        </span>
      ),
      to: "/",
    },
    {
      label: (
        <div className="relative md:w-full block fit">
          <MenuDropdown
            label={
              <span
                className={`text-sm pl-0 ${
                  location.pathname === "/transactions" ||
                  location.pathname === "/blocks" ||
                  location.pathname === "/pending-transactions"
                    ? " text-red-500 font-bold"
                    : " font-medium "
                } `}
              >
                Blockchain
              </span>
            }
            options={[
              [
                {
                  value: "Transactions",
                  link: "/transactions",
                },
                {
                  value: "Pending Transactions",
                  link: "pending-transactions",
                  divider: true,
                  disabled: true,
                },
              ],
              [
                {
                  value: "View Blocks",
                  link: "/blocks",
                },
              ],
            ]}
          />
        </div>
      ),
      dropdown: true,
    },
    {
      label: (
        <>
          <Tooltip
            style="light" // eslint-disable-line
            content="In Development 👨‍🔬"
            className="tooltip bg-white !border-none !drop-shadow-2xl !rounded !text-sm !text-be-navy-blue-700 !font-medium w-max !top-16 p-2"
          >
            <div className="relative md:w-full fit">
              <MenuDropdown
                disabled={true}
                label={
                  <span
                    className={`text-sm pointer-events-none ${
                      location.pathname === "/tokens"
                        ? "text-red-500 font-bold"
                        : " font-medium "
                    } `}
                  >
                    Token
                  </span>
                }
                options={[[{ value: "", link: "/" }]]}
              />
            </div>
          </Tooltip>
        </>
      ),
      dropdown: true,
    },
    {
      label: (
        <>
          <Tooltip
            style="light" // eslint-disable-line
            content="In Development 👨‍🔬"
            className="tooltip bg-white !border-none !drop-shadow-2xl !rounded !text-sm !text-be-navy-blue-700 !font-medium w-max !top-16 p-2"
          >
            <div className="relative ">
              <MenuDropdown
                disabled={true}
                label={
                  <span
                    className={`text-sm  ${
                      location.pathname === "/nfts"
                        ? "text-red-500 font-bold"
                        : " font-medium "
                    } `}
                  >
                    NFTs
                  </span>
                }
                options={[[{ value: "", link: "/" }]]}
              />
            </div>
          </Tooltip>
        </>
      ),
      dropdown: true,
    },
    {
      dropdown: true,
      label: (
        <span
          onClick={() => {
            dispatch(closeNavBar());
            daoModalCallback();
          }}
          className={`text-sm w-full ml-1 md:ml-0 text-be-navy-blue-700 transition-all duration-300 hover:text-be-navy-blue-300 cursor-pointer ${
            location.pathname === "/dao" ? "text-red-500 font-bold" : ""
          } `}
        >
          DAO
        </span>
      ),
      disabled: false,
    },
  ];
  return (
    <ul className="flex md:flex-row flex-col gap-5 lg:gap-6 md:gap-2  md:items-center md:w-fit w-full">
      {pages.map((page, index) => {
        return page.dropdown ? (
          <div key={index} className="relative">
            {page.label}
          </div>
        ) : (
          <div key={index} className="relative">
            <Link
              to={page.to ? page.to : "#"}
              label={page.label}
              disabled={page.disabled}
            />
          </div>
        );
      })}
    </ul>
  );
};

export default NavLinks;
