import React, { FC } from "react";

interface MonospacedDataBlockProps {
  contents: string;
  extended?: boolean;
  color?: string;
}

const MonospacedDataBlock: FC<MonospacedDataBlockProps> = ({
  contents,
  extended = false,
  color,
}) => {
  return (
    <>
      {extended ? (
        <textarea
          className={`font-mono h-[150px] w-full ${
            color === "gray" ? "text-gray-500" : "text-be-navy-blue-700"
          } bg-be-navy-blue-50 border border-be-navy-blue-100 rounded-lg p-2 resize-none text-sm`}
          disabled
          value={contents}
        />
      ) : (
        <input
          className={`font-mono w-full ${
            color === "gray" ? "text-gray-500" : "text-be-navy-blue-700"
          } bg-be-navy-blue-50 border border-be-navy-blue-100 rounded-lg p-2 text-sm`}
          disabled
          value={contents}
        />
      )}
    </>
  );
};

export default MonospacedDataBlock;
