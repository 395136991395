import { useEffect, useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { Flowbite, Progress, CustomFlowbiteTheme } from "flowbite-react";
import { useConfig } from "wagmi";
import { getClient } from "@wagmi/core";
import { getBlock, getTransactionReceipt } from "wagmi/actions";
import { getContract, decodeEventLog, formatUnits } from "viem";

import { joseon } from "../../wagmi/joseon";
import { fetchContractInfo } from "../../api/contractsApi";
import { governorEvents } from "../../ABI/governorEvents";
import GlobalClient from "../../recoil/GlobalClient";
import FullpageLoader from "../../features/common/FullpageLoader/FullpageLoader";
import Wrapper from "../../features/common/Wrapper/Wrapper";
//import DAOstatus from "../../features/DAOstatus/DAOstatus";
//import Badge from "../../features/common/Badge/Badge";
import BeTab from "../../features/common/BlockTabs/BlockTabs";
import InternalLink from "../../features/common/InternalLink/InternalLink";

import DAOVoteModal from "../../features/DAOVoteModal/DAOVoteModal";
import Container from "../../features/common/Container/Container";
import StatusBadge from "../../features/common/StatusBadge/StatusBadge";

import abstainIcon from "../../assets/icons/abstain.svg";
import check from "../../assets/icons/check.svg";
import cross from "../../assets/icons/cross.svg";

const progressTheme: CustomFlowbiteTheme = {
  progress: {
    base: "w-full overflow-hidden rounded-full bg-be-navy-blue-100 dark:bg-gray-700 ",
    label: "mb-1 flex justify-between font-medium dark:text-white",
    bar: "rounded-full text-center font-medium leading-none text-cyan-300 dark:text-cyan-100 space-x-2 bg-red-500",
    color: {
      dark: "bg-gray-600 dark:bg-gray-300",
      blue: "bg-blue-500",
    },
    size: {
      sm: "h-1",
      md: "h-2.5",
      lg: "h-4",
      xl: "h-6",
    },
  },
};

const generateTabItems = (
  description: string,
  targets: string,
  values: string,
  code: string,
) => {
  return [
    {
      title: "Description",
      content: (
        <div className="text-gray-500 text-xs md:text-sm font-medium space-y-2 py-2 px-4">
          <p>{description}</p>
        </div>
      ),
    },
    {
      title: "Actions",
      content: (
        <div className="py-2 px-4 font-source text-sm break-words font-medium text-gray-500 whitespace-wrap">
          Targets:
          <br />
          {targets}
          <br />
          <br />
          Values:
          <br />
          {values}
        </div>
      ),
    },
    {
      title: "Executable code",
      content: (
        <div className="py-2 px-4 font-source text-sm break-words font-medium text-gray-500 whitespace-wrap">
          {code}
        </div>
      ),
    },
  ];
};

const DAOProposal = () => {
  const params = useParams();
  const config = useConfig();
  const client = getClient(config);
  const qClient = useRecoilValue(GlobalClient);
  const [proposalInfo, setProposalInfo] = useState({});
  const [daoContract, setDaoContract] = useState(null); // eslint-disable-line
  const [votes, setVotes] = useState({});
  const [tabItems, setTabItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [proposalTimeDiff, setProposalTimeDiff] = useState({
    start: 0n,
    end: 0n,
  });
  const [alertStatus, setAlertStatus] = useState({
    open: false,
    success: false,
    message: "",
  });

  const castVote = useCallback(
    (support: number, reason?: string) => {
      if (qClient) {
        const qDaoContract = getContract({
          client: qClient,
          // @ts-expect-error
          address: params.contractAddress,
          // @ts-expect-error
          abi: daoContract.abi,
        });

        if (!reason) {
          // @ts-expect-error
          qDaoContract.write
            // @ts-expect-error
            .castVote([proposalInfo.id, support])
            .then((res: any) => {
              // alert("Tx: " + res);
              setAlertStatus({
                open: true,
                success: true,
                message: "Vote casted! Tx: " + res,
              });
            })
            .catch((e: any) => {
              // alert(e.message);
              setAlertStatus({
                open: true,
                success: false,
                message: e.message,
              });
            });
        } else {
          // @ts-expect-error
          qDaoContract.write
            // @ts-expect-error
            .castVoteWithReason([proposalInfo.id, support, reason])
            .then((res: any) => {
              // alert("Vote casted! Tx: " + res);
              setAlertStatus({
                open: true,
                success: true,
                message: "Vote casted! Tx: " + res,
              });
            })
            .catch((e: any) => {
              alert(e.message);
              setAlertStatus({
                open: true,
                success: false,
                message: e.message,
              });
            });
        }
      } else {
        alert("Please connect an account first");
      }
    },
    [daoContract, proposalInfo, qClient, params.contractAddress],
  );

  const getVotingPower = useCallback(async () => {
    if (qClient) {
      try {
        // @ts-expect-error
        var timepoint = await daoContract.read.clock([]);
      } catch {
        alert("Cannot read clock");

        return false;
      }

      try {
        // @ts-expect-error
        var power = await daoContract.read.getVotes([
          // @ts-expect-error
          qClient.account.address,
          timepoint - 10,
        ]);

        return formatUnits(power, joseon.nativeCurrency.decimals);
      } catch (e: any) {
        alert("Cannot get voting power");

        return false;
      }
    } else {
      return false;
    }
  }, [daoContract, qClient]);

  useEffect(() => {
    // @ts-ignore
    fetchContractInfo(client, params.contractAddress).then((result: any) => {
      if (result.abi) {
        const daoContract = getContract({
          client: client,
          // @ts-ignore
          address: params.contractAddress,
          abi: result.abi,
        });

        // @ts-ignore
        setDaoContract(daoContract);
        getTransactionReceipt(config, {
          // @ts-ignore
          hash: params.proposalTx,
        }).then((txReceiptResult: any) => {
          getBlock(config, {
            blockNumber: txReceiptResult.blockNumber,
          }).then((blockResult: any) => {
            const decodedLog = decodeEventLog({
              abi: governorEvents,
              data: txReceiptResult.logs[0].data,
              topics: txReceiptResult.logs[0].topics,
            });

            daoContract.read
              .proposalVotes([decodedLog.args.proposalId])
              .then((votes: any) => {
                setVotes({
                  for: formatUnits(votes[1], joseon.nativeCurrency.decimals),
                  against: formatUnits(
                    votes[0],
                    joseon.nativeCurrency.decimals,
                  ),
                  abstain: formatUnits(
                    votes[2],
                    joseon.nativeCurrency.decimals,
                  ),
                  total: formatUnits(
                    votes[0] + votes[1] + votes[2],
                    joseon.nativeCurrency.decimals,
                  ),
                });
              });

            const description = decodedLog.args.description
              ? JSON.parse(decodedLog.args.description)
              : { title: "Untitled", description: "No Description" };
            const calldatas = decodedLog.args.calldatas.toString();
            const targets = decodedLog.args.targets.toString();
            const values = decodedLog.args.values.toString();

            setProposalInfo((pi) => {
              return {
                ...pi,
                id: decodedLog.args.proposalId,
                proposer: decodedLog.args.proposer,
                createdAt: new Date(
                  Number(blockResult.timestamp) * 1000,
                ).toLocaleString(),
                voteStart: decodedLog.args.voteStart,
                voteEnd: decodedLog.args.voteEnd,
                description: description,
                targets: decodedLog.args.targets,
                values: decodedLog.args.values,
                calldatas: decodedLog.args.calldatas,
              };
            });

            setProposalTimeDiff({
              start: decodedLog.args.voteStart,
              end: decodedLog.args.voteEnd,
            });

            setTabItems(
              // @ts-expect-error
              generateTabItems(
                description.description,
                targets,
                values,
                calldatas,
              ),
            );

            setIsLoading(false);
          });
        });
      }
    });
  }, [config, client, params.contractAddress, params.proposalTx]);

  if (isLoading) return <FullpageLoader />;

  return (
    <Flowbite theme={{ theme: progressTheme }}>
      <Container className="pt-16 md:pt-28">
        <div className="container space-y-3  md:space-y-6">
          <Wrapper>
            <div className="p-2 px-4 md:p-4">
              <div className="flex flex-col gap-4 md:flex-row md:justify-between">
                <div className="flex items-center justify-between md:gap-2">
                  <h2 className="text-lg font-bold flex gap-2 items-center">
                    {/** @ts-ignore **/}
                    {proposalInfo.description.title}
                    <StatusBadge
                      startTimestamp={proposalTimeDiff.start}
                      endTimestamp={proposalTimeDiff.end}
                    />
                  </h2>

                  {/**<Badge status="Active" />**/}
                </div>
                <div className="flex flex-col md:flex-row gap-2">
                  <div className="flex gap-2">
                    <DAOVoteModal
                      proposalInfo={proposalInfo}
                      callback={castVote}
                      votingPowerCallback={getVotingPower}
                      status={alertStatus}
                      setOpen={setAlertStatus}
                    />
                  </div>
                </div>
              </div>
              <div className="md:block">
                Proposed by{" "}
                <InternalLink
                  // @ts-ignore
                  blockchainRef={proposalInfo.proposer}
                  type="address"
                />{" "}
                {/** @ts-ignore **/}
                on {proposalInfo.createdAt}
              </div>
            </div>

            <hr className="hidden bg-be-navy-blue-100 my-2" />
            {/** <DAOstatus /> don't forget to show the hr before this line **/}
          </Wrapper>
          <Wrapper>
            <div className="flex font-bold text-base md:text-lg items-center gap-2 m-0">
              <div className="flex items-center w-full divide-x-[1px] divide-be-navy-blue-100">
                <h2 className="font-bold text-xl text-be-navy-blue-700 py-5 px-6">
                  Votes
                </h2>
                <div className="font-bold text-base text-be-navy-blue-700 flex gap-6 py-5 px-6">
                  Total:
                  <span className="text-blue-500 font-medium">
                    {/** @ts-expect-error **/}
                    {votes.total}
                  </span>
                </div>
                <hr className="hidden h-[70px] bg-gray-200 w-[1px] rounded-lg ml-2 mr-0 md:ml-6 md:mr-4" />
              </div>
              <span className="hidden font-medium text-xs md:text-sm text-gray-500">
                Participation Rate
              </span>
              <div className="hidden flex-1">
                <Flowbite theme={{ theme: progressTheme }}>
                  <Progress color="blue" size="sm" progress={25} />
                </Flowbite>
              </div>
              <span className="hidden text-sm md:text-base">25%</span>
            </div>
            <hr className="bg-be-navy-blue-100 h-[1.5px]" />
            <div className="grid justify-items-center grid-flow-row md:grid-flow-col divide-y-[1px] md:divide-y-0 md:divide-x-[1px] divide-be-navy-blue-100">
              <div className="flex flex-col justify-between w-full p-4 md:p-6">
                <img src={check} className="w-6" alt="Abstain Icon" />
                <div className="flex items-end justify-between gap-6">
                  <span className="text-xs text-be-navy-blue-500 uppercase">
                    For
                  </span>
                  <span className="font-medium text-xl md:text-4xl text-be-navy-blue-700">
                    {/** @ts-expect-error **/}
                    {votes.for}
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-between w-full p-4 md:p-6">
                <img src={cross} className="w-6" alt="Abstain Icon" />
                <div className="flex items-end justify-between gap-6">
                  <span className="text-xs text-be-navy-blue-500 uppercase">
                    Against
                  </span>
                  <span className="font-medium text-xl md:text-4xl text-be-navy-blue-700">
                    {/** @ts-expect-error **/}
                    {votes.against}
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-between w-full p-4 md:p-6">
                <img src={abstainIcon} className="w-6" alt="Abstain Icon" />
                <div className="flex items-end justify-between gap-6">
                  <span className="text-xs text-be-navy-blue-500 uppercase">
                    Abstain
                  </span>
                  <span className="font-medium text-xl md:text-4xl text-be-navy-blue-700">
                    {/** @ts-expect-error **/}
                    {votes.abstain}
                  </span>
                </div>
              </div>
            </div>
          </Wrapper>
          <Wrapper title="Details">
            <BeTab tabItems={tabItems} />
          </Wrapper>
        </div>
      </Container>
    </Flowbite>
  );
};

export default DAOProposal;
