import Button from "../common/Button/Button";
import Badge from "../common/Badge/Badge";
// import BEConnectWallet from "../common/BEConnectWallet/BEConnectWallet";
import BeTab from "../common/BlockTabs/BlockTabs";
import Wrapper from "../common/Wrapper/Wrapper";
import DAOcontactCard from "../DAOcontactCard/DAOcontactCard";
import InternalLink from "../common/InternalLink/InternalLink";
import FormatTimePeriod from "../common/FormatTimePeriod/FormatTimePeriod";

import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { IconTwitter } from "../../assets/constant/svg";
import { Link } from "react-router-dom";

// @ts-ignore
const DAOProjectInfo = ({ info }) => {
  const tabItems = [
    {
      title: "About",
      content: (
        <div className="text-be-navy-blue-700 space-y-4 md:space-y-9 md:mt-9">
          <h5 className="hidden">
            Sit elementum orci pharetra tristique at netus sapien. Pellentesque
            platea non venenatis.
          </h5>
          <div className="flex md:gap-10 gap-4">
            <a
              href={info.url}
              target="_blank"
              className={
                (info.url ? "" : "hidden ") + "flex items-center gap-2"
              }
            >
              <GlobeAltIcon className="w-6 h-6" />
              <span>{info.url}</span>
            </a>
            <a
              href="/"
              target="_blank"
              className="hidden flex items-center gap-2"
            >
              <IconTwitter />
              <span>@projectname</span>
            </a>
          </div>
          <div className="flex items-end mb-auto gap-2">
            <Badge status="ERC20" color="light" size="sm" />
            <Badge
              status={info.tokenSupply + " shares"}
              color="light"
              size="sm"
            />
          </div>
        </div>
      ),
    },
    /**
    {
      title: "Description",
      content: (
        <p className="text-be-navy-blue-700 text-sm">
          Eu massa molestie at congue blandit cursus dignissim. Donec in lorem
          egestas venenatis nulla non sed. Ac sit vestibulum fringilla imperdiet
          sagittis aenean nunc feugiat non. At adipiscing congue nibh erat
          aenean posuere. Pretium pulvinar non morbi fermentum sagittis interdum
          aliquam pretium vitae. Suscipit non tortor faucibus mi turpis lacus.
          Suspendisse felis vulputate scelerisque vel cras urna ornare amet
          luctus. At quis molestie est integer aliquet. Nullam id turpis sed
          ornare purus. Nec tortor id massa ut cursus arcu erat diam. Enim donec
          eget venenatis nam amet orci leo mattis. Neque vitae faucibus urna leo
          volutpat viverra eros. Ornare gravida fames Sem lorem enim proin velit
          diam proin etiam vulputate. Diam aliquam venenatis viverra vestibulum
          proin eget. Et nec ornare imperdiet donec consectetur lorem aliquam
          risus commodo. Ac enim donec adipiscing tristique ac pharetra velit
          leo. Pretium nullam ut fringilla vitae. Diam mi pellentesque
          adipiscing eget amet ut ultrices id. Lobortis nulla est ultricies in
          purus mattis. Amet magna erat odio risus nec sollicitudin eget felis
          in. Habitasse libero phasellus massa metus. Quis consectetur varius
          sed fames volutpat egestas.
        </p>
      ),
    },**/
    {
      title: "DAO Parameters",
      content: (
        <div className="flex md:flex-row flex-col gap-4">
          <DAOcontactCard
            title="Contracts"
            params={{
              DAO: {
                label: "DAO",
                value: (
                  <InternalLink
                    blockchainRef={info.governorAddress}
                    type="address"
                  />
                ),
              },
              Token: {
                label: "Token",
                value: (
                  <InternalLink
                    blockchainRef={info.tokenAddress}
                    type="address"
                  />
                ),
              },
            }}
          />
          <DAOcontactCard
            title="Parameters"
            params={{
              Quorum: {
                label: "Quorum",
                value: info.quorum + "%",
              },
              "Voting Period": {
                label: "Voting Period",
                value: (
                  <FormatTimePeriod timePeriod={Number(info.votingPeriod)} />
                ),
              },
              "Voting Delay": {
                label: "Voting Delay",
                value: (
                  <FormatTimePeriod timePeriod={Number(info.votingDelay)} />
                ),
              },
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <Wrapper>
      <div className="p-2 py-4 space-y-2 md:space-y-5">
        <div className="flex md:flex-row flex-col justify-between items-center">
          <div className="flex justify-start items-center gap-2 w-full md:mb-0 mb-2">
            <h3 className="text-xl font-bold text-be-navy-blue-700">
              {info.name}
            </h3>
          </div>
          <div className="flex md:flex-row flex-col md:justify-end justify-center md:items-center items-start gap-2 w-full">
            <Link to={"/dao/" + info.governorAddress + "/propose"}>
              <Button
                color="red"
                label="Create New Proposal"
                className="md:w-fit w-full"
              />
            </Link>
          </div>
        </div>
        <div className="w-full">
          <BeTab tabItems={tabItems} />
        </div>
      </div>
    </Wrapper>
  );
};
export default DAOProjectInfo;
