import React from "react";
import { Progress } from "flowbite-react";

interface GasPercentageProps {
  color: string;
  used: bigint;
  max: bigint;
}

const GasPercentage: React.FC<GasPercentageProps> = ({ max, used }) => {
  const gasLimit = Number(max);
  const gasUsed = Number(used);
  const percentageUsed = (gasUsed / gasLimit) * 100;
  const roundedPercentage = Math.round(percentageUsed * 100) / 100;

  return (
    <div className="flex font-medium items-center gap-2">
      <span>{gasUsed.toLocaleString("en-US").toString()}</span>
      <div className="md:max-w-[80px] w-full rounded-full">
        <Progress
          className="w-full"
          color={percentageUsed >= 100 ? "red" : "green"}
          progress={percentageUsed}
        />
      </div>
      <span
        className={
          "font-sans font-light text-sm md:text-base text-center min-w-16 " +
          (percentageUsed >= 100 ? "text-red-500" : "text-green-500")
        }
      >
        {roundedPercentage}%<br />
        <span className="text-xs md:text-base">Gas Target</span>
      </span>
    </div>
  );
};

export default GasPercentage;
