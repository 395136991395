// @ts-nocheck
import React, { FC, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Table, Tabs } from "flowbite-react";
import { useConfig } from "wagmi";
import { getClient } from "@wagmi/core";
import { getBalance } from "wagmi/actions";
import { getContract, getAbiItem } from "viem";

import AddressInfoCards from "../../features/common/AddressInfoCards/AddressInfoCards";
import { copyToClipboard } from "../../helpers/copyToClipboard";
import Dropdown from "../../features/common/Dropdown/Dropdown";
import ContractTab from "../../features/ContractTab/ContractTab";
import formatTxBlockToTxs from "../../helpers/formatTxBlockToTxs";
import FullpageLoader from "../../features/common/FullpageLoader/FullpageLoader";
import { fetchContractInfo } from "../../api/contractsApi";

import icon_glob from "../../assets/icons/icon_glob.svg";
import icon_sort_ascending from "../../assets/icons/icon_sort_ascending.svg";
import {
  ChatBubbleLeftEllipsisIcon,
  DocumentDuplicateIcon,
  QrCodeIcon,
} from "@heroicons/react/24/outline";
import AccountTransactionsTableRow from "../../features/Rows/AccountTransactionsTableRow/AccountTransactionsTableRow";
import HomeTable from "../../features/common/HomeTable/HomeTable";

const tabsTheme: CustomFlowbiteTheme["tabs"] = {
  tablist: {
    base: "flex items-center  gap-8 text-center overflow-hidden",
    styles: {
      default: "border-b border-gray-200 ",
    },
    tabitem: {
      base: "flex items-center justify-center max-w-[165px] w-full py-4 rounded-t-lg text-xs md:text-sm font-medium first:ml-0 focus:ring-0 focus:ring-transparent focus:outline-none ",
      styles: {
        default: {
          base: "rounded-t-lg",
          active: {
            on: "font-bold text-blue-500 border-b-[2px] border-transparent",
            off: "font-bold text-gray-500 border-b-[2px] border-transparent",
          },
        },
      },
    },
  },
};

const AddressDetails: FC = () => {
  const params = useParams();
  const config = useConfig();
  const client = getClient(config);

  const [balanceData, setBalanceData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [contract, setContract] = useState(null);
  const [dao, setDao] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchBalance = async (hash) => {
    const data = await getBalance(config, {
      // @ts-ignore
      address: hash,
    });

    const history = await client.request({
      method: "modchain_historyByAddress",
      params: [hash],
    });

    setBalanceData(data);

    setHistoryData(formatTxBlockToTxs(history.results));
  };

  const fetchContract = async (hash, client) => {
    fetchContractInfo(client, hash).then((result: any) => {
      if (result) {
        setContract(
          getContract({
            client: client,
            // @ts-ignore
            address: params.hash,
            abi: result.abi,
          }),
        );
      } else {
        setContract(false);
      }
    });
  };

  useEffect(() => {
    setDao(null);
    setIsLoading(true);
    fetchBalance(params.hash);
    fetchContract(params.hash, client);
  }, [params.hash]); // eslint-disable-line

  useEffect(() => {
    if (
      isLoading &&
      balanceData !== null &&
      historyData !== null &&
      contract !== null
    ) {
      if (contract) {
        const item = getAbiItem({
          abi: contract.abi,
          name: "setVotingPeriod",
        });

        if (item) {
          setDao(contract.address);
        }
      }

      setIsLoading(false);
    }
  }, [balanceData, historyData, contract, isLoading]);

  if (isLoading) {
    return <FullpageLoader />;
  } else {
    return (
      <section className="bg-be-navy-blue-50 py-6 min-h-screen md:w-full w-screen mt-8 md:mt-0">
        <div className="container space-y-6 md:space-y-12">
          <div className="flex flex-row flex-wrap items-center space-x-2">
            <div className="flex items-center space-x-1">
              <img
                className="hidden"
                src={icon_glob}
                alt="icon glob"
                loading="lazy"
              />
              <p className="font-sans font-semibold text-lg text-be-navy-blue-700">
                Address
              </p>
            </div>
            <p className="font-sans font-normal text-xs md:text-base text-be-navy-blue-700">
              {params.hash}
            </p>
            <div className="flex items-center gap-2 md:gap-6 text-be-navy-blue-100">
              <DocumentDuplicateIcon
                onClick={() => copyToClipboard(params.hash)}
                className="w-4 h-4 hover:text-be-navy-blue-700 cursor-pointer"
              />
              <QrCodeIcon className="hidden w-4 h-4 hover:text-be-navy-blue-700 cursor-pointer" />
              <ChatBubbleLeftEllipsisIcon className="hidden w-4 h-4 hover:text-be-navy-blue-700 cursor-pointer" />
            </div>
          </div>
          <AddressInfoCards balance={balanceData} dao={dao} />
          <Tabs theme={tabsTheme}>
            <Tabs.Item active title="Transactions">
              <div className="bg-white border-2 border-gray-200 rounded-xl p-6 px-4 space-y-6 md:space-y-12">
                <div className="flex flex-col-reverse md:flex-row gap-4 md:justify-between items-start md:items-center">
                  <div className="flex  items-center space-x-0.5 md:space-x-1">
                    <img
                      src={icon_sort_ascending}
                      alt="icon sort-ascending"
                      loading="lazy"
                    />
                    <div>
                      <div className="font-sans font-medium text-be-navy-blue-700 flex items-center whitespace-nowrap">
                        Latest {historyData.length} from a total of
                        <p className=" md:text-sm text-xs text-blue-500 px-1">
                          {" "}
                          {historyData.length}{" "}
                        </p>
                        transaction
                      </div>
                    </div>
                  </div>
                  <div className="hidden relative block-inline ml-auto">
                    <Dropdown
                      label="More"
                      full
                      size="sm"
                      color="grey"
                      alignment="right"
                      options={[
                        [
                          {
                            value: "View in full screen",
                          },
                          {
                            value: "Print chart",
                          },
                        ],
                        [
                          {
                            value: "Download PNG image",
                          },
                          {
                            value: "Download JPG image",
                          },
                          {
                            value: "Download PDF document",
                          },
                          {
                            value: "Download SVG vector image",
                          },
                        ],
                      ]}
                    />
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <HomeTable>
                    <Table.Head>
                      <Table.HeadCell className="!bg-white">
                        Txn Hash
                      </Table.HeadCell>
                      <Table.HeadCell className="!bg-white">
                        Block
                      </Table.HeadCell>
                      <Table.HeadCell className="text-red-600 !bg-white">
                        Age
                      </Table.HeadCell>
                      <Table.HeadCell className="!bg-white">
                        From
                      </Table.HeadCell>
                      <Table.HeadCell className="!bg-white">To</Table.HeadCell>
                      <Table.HeadCell className="!bg-white">
                        Value
                      </Table.HeadCell>
                      <Table.HeadCell className="whitespace-nowrap !bg-white">
                        Txn Fee
                      </Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                      {historyData.map((data, index) => (
                        <AccountTransactionsTableRow key={index} tx={data} />
                      ))}
                    </Table.Body>
                  </HomeTable>
                </div>
                <div className="flex justify-end">
                  <Link to="/transactions">
                    <button
                      type="button"
                      className="flex items-center py-1 md:py-2 px-2 md:px-4 font-regular rounded-lg border-2 border-gray-200 bg-gray-100 disabled:cursor-not-allowed disabled:bg-gray-5"
                    >
                      View All Transactions
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 ml-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                        />
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
            </Tabs.Item>
            {contract ? (
              <Tabs.Item title="Contract">
                <ContractTab contract={contract} />
              </Tabs.Item>
            ) : null}
          </Tabs>
        </div>
      </section>
    );
  }
};

export default AddressDetails;
