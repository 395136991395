import React from "react";
import { Table } from "flowbite-react";

import InternalLink from "../../common/InternalLink/InternalLink";
import iconCube from "../../../assets/icons/iconCube.svg";
import {
  formatMobileTimestamp,
  formatTimestamp,
} from "../../../helpers/formatTimestamp";
import AddressFieldComponent from "../../common/AddressFieldComponent/AddressFieldComponent";

// @ts-ignore
const MiniBlockRow: React.FC = ({ data }) => {
  return (
    <Table.Row className="border-b border-be-navy-blue-100 flex justify-between">
      <Table.Cell className="flex pr-2 md:pr-4 pl-0 min-w-48 md:w-auto items-center">
        <div className="flex items-center gap-1 md:gap-3 ">
          <div className="bg-be-navy-blue-50 rounded-lg h-[38px] w-[38px] p-2 ">
            <img src={iconCube} alt="cube icon" className="w-full h-full" />
          </div>
          <div className="flex flex-row md:flex-col items-center md:items-start gap-3 md:gap-1 text-sm">
            <div>
              <InternalLink
                blockchainRef={data.number.toString()}
                type="block"
                clipboard={false}
              />
            </div>
            <span className="text-be-navy-blue-700 font-medium">
              <span className="hidden md:block">
                {formatTimestamp(data.timestamp)}
              </span>
              <span className="block md:hidden">
                {formatMobileTimestamp(data.timestamp)}
              </span>
            </span>
          </div>
        </div>
      </Table.Cell>
      <Table.Cell className="md:block pl-2 md:pl-4 pr-0 min-w-36">
        <div className="flex flex-col md:flex-row md:items-center gap-1">
          <span className="font-medium ">Validated by </span>
          <AddressFieldComponent address={data.miner} />
        </div>
        <div className="flex gap-1 font-medium  ">
          <span className="text-blue-500 font-bold">
            {data.transactions.length} txns
          </span>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default MiniBlockRow;
