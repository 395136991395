import React, {
  useState,
  useRef,
  useEffect,
  ReactNode,
  FC,
  Fragment,
} from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeNavBar } from "../../../redux/reducers/toggleSlice";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "flowbite-react";

interface MenuDropdownProps {
  options: Array<
    {
      value: string;
      icon?: ReactNode;
      link?: string;
      divider?: boolean;
      disabled?: boolean;
    }[]
  >;
  disabled?: boolean;
  label: string | ReactNode;
  onChange?: any;
}

const MenuDropdown: FC<MenuDropdownProps> = ({
  options,
  label,
  onChange,
  disabled,
}) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  const handleValueSelect = (value: string) => {
    if (onChange) {
      onChange({
        target: {
          value,
        },
      });
    }
  };

  const handleButtonClick = (e: any) => {
    if (disabled) return;
    setIsDropdownActive(!isDropdownActive);
    if (e.target.nodeName === "A" || e.target.nodeName === "a") {
      dispatch(closeNavBar());
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef} onClick={handleButtonClick}>
      <div
        className={
          "dropdown-button py-2 md:p-3 flex items-center cursor-pointer " +
          (disabled ? "text-gray-300" : "")
        }
      >
        {label}
        <ChevronDownIcon className="w-5 h-5 ml-2" />
      </div>
      {isDropdownActive && (
        <div className="dropdown-content relative md:absolute md:top-[60px] left-0 bg-white w-full md:w-max rounded-b-md md:shadow py-2 md:py-0 md:pb-3">
          <div className="hidden md:flex w-full md:w-[236px] h-[2px] bg-red-500 mb-3"></div>
          <div className="px-2">
            {options.map((option, index) => (
              <div key={index}>
                {option.map((op, index) =>
                  op.link ? (
                    <Fragment key={index}>
                      {op.disabled ? (
                        <Tooltip
                            style="light" // eslint-disable-line
                          content="In Development 👨‍🔬"
                          placement="right"
                          className="tooltip bg-white !border-none !drop-shadow-2xl !rounded !text-sm !text-be-navy-blue-700 !font-medium w-max p-2"
                        >
                          <Link
                            to={op.link}
                            onClick={() => handleValueSelect(op.value)}
                            className="block p-2 text-gray-300 pointer-events-none"
                          >
                            {op.value}
                          </Link>
                        </Tooltip>
                      ) : (
                        <Link
                          to={op.link}
                          onClick={() => handleValueSelect(op.value)}
                          className="block p-2 transition-all duration-300 hover:text-red-500 hover:bg-red-50 hover:rounded cursor-pointer font-medium"
                        >
                          {op.value}
                        </Link>
                      )}

                      {op.divider && (
                        <div className="w-full h-[1px] bg-gray-200"></div>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment key={index}>
                      <button
                        onClick={() => handleValueSelect(op.value)}
                        className="block p-2 transition-all duration-300 hover:text-red-500 hover:bg-red-50 hover:rounded cursor-pointer font-medium"
                      >
                        {op.value}
                      </button>
                      {op.divider && (
                        <div className="w-full h-[1px] bg-gray-200"></div>
                      )}
                    </Fragment>
                  ),
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuDropdown;
