import React, { useState, Fragment } from "react";

import Button from "../../common/Button/Button";
import TransferTokens from "../../TransferTokens/TransferTokens";

import {
  ArrowPathIcon,
  ArrowRightIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";

// @ts-ignore
const CreateProposalActions = ({ pActions, callback, contractInfo }) => {
  const [actions, setActions] = useState(pActions);

  const addAction = () => {
    setActions((a: any) => [
      ...a,
      {
        type: "",
        data: {
          target: "",
          value: "",
          callDatas: "0x0",
        },
      },
    ]);
  };

  const removeAction = (index: number) => {
    setActions((a: any) => {
      return a.filter((_: any, i: number) => i !== index);
    });

    callback(actions);
  };

  const setActionType = (index: number, type: string) => {
    setActions((a: any) => {
      return a.map((item: any, i: number) => {
        if (i === index) {
          return { ...item, type: type };
        }

        return item;
      });
    });

    callback(actions);
  };

  const setActionData = (index: number, data: any) => {
    setActions((a: any) => {
      return a.map((item: any, i: number) => {
        if (i === index) {
          return { ...item, data: data };
        }

        return item;
      });
    });

    callback(actions);
  };

  return (
    <div className="space-y-9 my-9">
      <p>
        We currently only implement transfer of MUN to a specified address, more
        functionality will come soon to expand your proposals' horizons.
      </p>
      <hr className="border-grey-100  w-full border-1 "></hr>

      <div className="space-y-9">
        {actions.map((action: any, index: number) => {
          return (
            <div key={index} className="space-y-2">
              <div className="flex">
                <h3 className="text-base font-bold text-red-500 flex-auto">
                  Action #{index + 1}
                </h3>
                <div className="justify-end">
                  <Button
                    size="xs"
                    color="red"
                    label={"Remove action #" + (index + 1)}
                    disabled={action.data.lock}
                    onClick={() => {
                      removeAction(index);
                    }}
                  />
                </div>
              </div>
              {action.type === "" && (
                <>
                  <Button
                    className="justify-start min-w-[300px]"
                    color="white"
                    label="Transfer tokens"
                    icon={<ArrowRightIcon className="h-5 w-5 text-blue-600" />}
                    onClick={() => setActionType(index, "transfer")}
                  />
                  <Button
                    className="justify-start min-w-[300px]"
                    color="white"
                    label="Swap"
                    disabled={true}
                    onClick={() => setActionType(index, "swap")}
                    icon={<ArrowPathIcon className="h-5 w-5 text-blue-600" />}
                  />

                  <Button
                    className="justify-start min-w-[300px]"
                    color="white"
                    label="Custom"
                    disabled={true}
                    icon={
                      <PencilSquareIcon className="h-5 w-5 text-blue-600" />
                    }
                    onClick={() => setActionType(index, "custom")}
                  />
                </>
              )}
              {action.type === "transfer" && (
                <TransferTokens
                  // @ts-ignore
                  contractInfo={contractInfo}
                  action={action.data}
                  callback={(data: any) => setActionData(index, data)}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="flex gap-2.5 justify-center ml-auto">
        <Button color="white" label="Add action" onClick={addAction} />
      </div>
    </div>
  );
};

export default CreateProposalActions;
