let blockData: any = {
  block: 17412450,
  age: "30 secs ago",
  validator: "0xE7E2cB8c81c10FF191A73FE266788C9Ce62EC754",
  validation_age: "12 secs",
  txn: 0.254,
  gasUsed: {
    value: "11,945,634",
    percentage_label: "40.45%, -19%",
    percentage: 40.45,
  },
  price: 0.213454,
  gasLimit: 30_000_000,
  baseFee: 1212123,
  reward: 0.01858,
  burntFee: { value: 0.331921, percentage: -20.12 },
};

let TransactionData: any = {
  txnHash: "0xE7E2cB8c81c10FF191A73FE266788C9Ce62EC754",
  method: "Execute",
  block: 17412450,
  age: "12 secs ago",
  from: "0xE7E2cB8c81c10FF191A73FE266788C9Ce62EC754",
  to: "1xE7E2cB8c81c10FF191A73FE266788C9Ce62EC754",
  value: 334244,
  txn: 0.01858,
  price: 322.2,
};

const blocksData: Array<any> = [];
for (let i = 0; i < 10; i++) {
  blocksData.push(blockData);
}
const TransactionsData: Array<any> = [];
for (let i = 0; i < 10; i++) {
  TransactionsData.push(TransactionData);
}

export { TransactionData, TransactionsData, blocksData, blockData };
