import React, { useState, useEffect, useRef } from "react";

interface InfiniteScrollProps {
  fetchData: any;
}

function InfiniteScroll({ fetchData }: InfiniteScrollProps) {
  const [loading, setLoading] = useState(false);
  const more = useRef(true);

  const observerRef = useRef(null);

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;

    if (entry.isIntersecting && more.current) {
      setLoading(true);
      fetchData().then((isMore: string) => {
        if (!isMore) {
          more.current = false;
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    });

    if (observerRef.current) {
      observer.observe(observerRef.current); // eslint-disable-line
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current); // eslint-disable-line
      }
    };
  }, []); // eslint-disable-line

  return (
    <>
      <tr className="h-6"></tr>
      <tr ref={observerRef}>
        <td colSpan={100} style={{ textAlign: "center" }}>
          {loading && "Loading..."}
          {!more.current && "No more data"}
        </td>
      </tr>
    </>
  );
}

export default InfiniteScroll;
