import { FC } from "react";

import {
  CheckCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

interface ConnectWalletProposalProps {
  checks: {
    connected: boolean;
    validChain: boolean;
    hasPower: boolean;
  };
}

const ConnectWalletProposal: FC<ConnectWalletProposalProps> = ({ checks }) => {
  const okCheck = (
    <CheckCircleIcon className="w-6 h-6 fill-green-100 stroke-green-500" />
  );
  const nokCheck = (
    <XCircleIcon className="w-6 h-6 fill-red-100 stroke-red-500" />
  );

  const infoCheck = (
    <InformationCircleIcon className="w-6 h-6 fill-blue-100 stroke-blue-500" />
  );

  return (
    <>
      <div className="md:px-4 md:py-16 py-5 px-0">
        <ul className="space-y-1 md:space-y-4">
          <li className="flex items-center gap-4">
            {checks.connected ? okCheck : nokCheck}
            <span className="text-be-navy-blue-500 break-words md:text-base text-xs">
              Wallet connection
            </span>
          </li>
          <li className="flex items-center gap-4">
            {checks.validChain ? okCheck : nokCheck}
            <span className="text-be-navy-blue-500 break-words md:text-base text-xs">
              Joseon Chain selected
            </span>
          </li>
          <li className="flex items-center gap-4">
            {checks.hasPower ? infoCheck : infoCheck}
            <span className="text-be-navy-blue-500 break-words md:text-base text-xs">
              Check your minimum voting power to propose
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ConnectWalletProposal;
