import {
  differenceInMilliseconds,
  formatDistanceToNow,
  differenceInSeconds,
  differenceInMinutes,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from "date-fns";

export const formatTimestamp = (timestamp: any) => {
  const timestampInSeconds = Number(timestamp);
  const timestampInMilliseconds = timestampInSeconds * 1000;

  const currentTime = new Date();
  const timeDifference = differenceInMilliseconds(
    currentTime,
    timestampInMilliseconds,
  );

  let formattedTimeAgo;

  if (timeDifference < 60000) {
    formattedTimeAgo = formatDistanceToNow(timestampInMilliseconds, {
      includeSeconds: true,
    });
  } else if (timeDifference < 3600000) {
    formattedTimeAgo = formatDistanceToNow(timestampInMilliseconds, {
      includeSeconds: false,
    });
  } else {
    formattedTimeAgo = formatDistanceToNow(timestampInMilliseconds, {
      includeSeconds: false,
    });
  }

  return formattedTimeAgo + " ago";
};

export const formatMobileTimestamp = (timestamp: any) => {
  const timestampInSeconds = Number(timestamp);
  const timestampInMilliseconds = timestampInSeconds * 1000;

  const currentTime = new Date();
  const timeDifferenceInSeconds = differenceInSeconds(
    currentTime,
    timestampInMilliseconds,
  );

  if (timeDifferenceInSeconds < 10) {
    return "10s ago";
  } else if (timeDifferenceInSeconds < 60) {
    return `${timeDifferenceInSeconds}s ago`;
  }

  const timeDifferenceInMinutes = differenceInMinutes(
    currentTime,
    timestampInMilliseconds,
  );

  if (timeDifferenceInMinutes < 60) {
    return `${timeDifferenceInMinutes}m ago`;
  }

  const timeDifferenceInDays = differenceInDays(
    currentTime,
    timestampInMilliseconds,
  );

  if (timeDifferenceInDays < 7) {
    return `${timeDifferenceInDays}d ago`;
  }

  const timeDifferenceInWeeks = differenceInWeeks(
    currentTime,
    timestampInMilliseconds,
  );

  if (timeDifferenceInWeeks < 4) {
    return `${timeDifferenceInWeeks}w ago`;
  }

  const timeDifferenceInMonths = differenceInMonths(
    currentTime,
    timestampInMilliseconds,
  );

  if (timeDifferenceInMonths < 12) {
    return `${timeDifferenceInMonths}mo ago`;
  }

  const timeDifferenceInYears = differenceInYears(
    currentTime,
    timestampInMilliseconds,
  );

  return `${timeDifferenceInYears}y ago`;
};
