import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ContractInput from "../ContractInput/ContractInput";
import ContractOutputs from "../ContractOutputs/ContractOutputs";
import MonospacedDataBlock from "../common/BlockchainData/MonospacedDataBlock/MonospacedDataBlock";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Button from "../common/Button/Button";

interface ContractFunctionProps {
  abiEntry: any;
  execute: any;
  isOpen: boolean;
  onToggle: () => void;
}

const ContractFunction: React.FC<ContractFunctionProps> = ({
  abiEntry,
  execute,
  onToggle,
  isOpen,
}) => {
  const [returnedData, setReturnedData] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const executeParams = {
      functionName: abiEntry.name,
      stateMutability: abiEntry.stateMutability,
      args: [],
    };

    const userArgs = Object.fromEntries(new FormData(e.target).entries());

    for (var key in userArgs) {
      // @ts-ignore
      executeParams.args.push(userArgs[key]);
    }

    execute(executeParams).then((result: any) => {
      setReturnedData(result);
    });
  };

  return (
    <div className="border border-blue-50 rounded-md">
      <motion.button
        className={`w-full outline-none flex items-center justify-between py-3 px-4 border border-blue-50 ${
          isOpen ? "rounded-t-md" : "rounded-md"
        } font-semibold text-sm bg-be-navy-blue-50 text-be-navy-blue-300 transition-all duration-300 ease-in-out hover:bg-blue-50 hover:text-blue-600 first-letter:capitalize`}
        initial={false}
        onClick={onToggle}
      >
        <h3 className="font-medium">
          {abiEntry.name} ({abiEntry.stateMutability})
        </h3>
        <ChevronDownIcon
          className={`h-5 w-5 transform ${
            isOpen ? "rotate-180 text-blue-600" : "text-be-navy-blue-300"
          }`}
        />
      </motion.button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3 }}
          >
            <div className="p-4">
              <h3 className="text-be-navy-blue-700 font-bold text-sm mb-2">
                Parameters
              </h3>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-4 mb-6"
              >
                {abiEntry.inputs ? (
                  abiEntry.inputs.map((abiInput: object, index: number) => {
                    // @ts-ignore
                    return <ContractInput key={index} abiInput={abiInput} />;
                  })
                ) : (
                  <span className="italic">No inputs</span>
                )}
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    color="redTransparent"
                    label="Submit"
                    outline={true}
                  />
                </div>
              </form>
              <h3 className="text-be-navy-blue-700 font-bold text-sm mb-2">
                Returns
              </h3>
              <ContractOutputs abiOutputs={abiEntry.outputs} />
              <hr className="my-6 w-full h-[1px] bg-be-navy-blue-100" />
              <h3 className="mt-5 text-be-navy-blue-700 font-bold text-sm mb-2">
                Execution Return
              </h3>
              {returnedData ? (
                <MonospacedDataBlock color="gray" contents={returnedData} />
              ) : (
                <MonospacedDataBlock
                  color="gray"
                  contents="Function not executed yet"
                />
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ContractFunction;
