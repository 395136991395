import { FC } from "react";

interface DAOcontactCardProps {
  title: string;
  params: Object;
}

const DAOcontactCard: FC<DAOcontactCardProps> = ({ title, params }) => {
  return (
    <div className="max-w-[306px] w-full bg-gray-50 border-[1px] border-be-navy-blue-100 rounded-lg p-4">
      <h3 className="text-blue-500">{title}</h3>
      {Object.entries(params).map(([key, param]) => (
        <div className="flex justify-between w-full" key={key}>
          <h5 className="font-semibold">{param.label}</h5>
          <div className="flex">
            <h5 className="text-ellipsis text-gray-500">{param.value}</h5>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DAOcontactCard;
