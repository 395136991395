import { FC, useEffect, useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useParams, useNavigate } from "react-router-dom";
import { useConfig } from "wagmi";
import { getClient } from "@wagmi/core";
import { getContract, parseUnits } from "viem";
// @ts-ignore
import { rest } from "@karpeleslab/klbfw";

import { fetchContractInfo } from "../../api/contractsApi";
import { joseon } from "../../wagmi/joseon";
import FullpageLoader from "../../features/common/FullpageLoader/FullpageLoader";
import Button from "../../features/common/Button/Button";
import StepperNewProposal from "../../features/StepperNewProposal/StepperNewProposal";
import CreateProposalActions from "../../features/StepperNewProposal/CreateProposalActions/CreateProposalActions";
import ConnectWalletProposal from "../../features/StepperNewProposal/ConnectWalletProposal/ConnectWalletProposal";
import DescribeProposition from "../../features/StepperNewProposal/DescribeProposition/DescribeProposition";
import PreviewProposal from "../../features/StepperNewProposal/PreviewProposal/PreviewProposal";
import Container from "../../features/common/Container/Container";
import GlobalClient from "../../recoil/GlobalClient";
import Alert from "../../features/common/Alert/Alert";
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import CreateProposalSuccess from "../../features/CreateProposalSuccess/CreateProposalSuccess";

const CreateProposal: FC = () => {
  const params = useParams();
  const config = useConfig();
  const client = getClient(config);
  const qClient = useRecoilValue(GlobalClient);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [txnHash, setTxnHash] = useState("");
  const [contractInfo, setContractInfo] = useState({
    corporationId: "",
    name: "",
    url: "",
    governorAddress: "",
    tokenAddress: "",
    tokenSupply: "",
    quorum: "",
    votingDelay: "",
    votingPeriod: "",
    abi: [],
  });

  const [proposalChecks, setProposalChecks] = useState({
    connected: false,
    validChain: false,
    hasPower: false,
  });

  const [proposal, setProposal] = useState({
    description: {
      title: "",
      description: "",
    },
    actions: [],
  });

  const [alertStatus, setAlertStatus] = useState({
    open: false,
    success: false,
    message: "",
  });

  const handleTitle = (e: any) => {
    setProposal((p) => {
      return {
        ...p,
        description: {
          description: p.description.description,
          title: e.target.value,
        },
      };
    });
  };

  const handleDescription = (e: any) => {
    setProposal((p) => {
      return {
        ...p,
        description: {
          title: p.description.title,
          description: e.target.value,
        },
      };
    });
  };

  const getProposalTargetsForSend = (p: any) => {
    return p.actions.map((a: any, i: number) => {
      return a.data.target;
    });
  };

  const getProposalValuesForSend = (p: any) => {
    return p.actions.map((a: any, i: number) => {
      return parseUnits(a.data.value, joseon.nativeCurrency.decimals);
    });
  };

  const getProposalCallDatasForSend = (p: any) => {
    return p.actions.map((a: any, i: number) => {
      return a.data.callDatas;
    });
  };

  const saveAction = (data: any) => {
    setProposal((p) => {
      return { ...p, actions: data };
    });
  };

  const handleAlert = () => {
    setAlertStatus((prev: any) => {
      return { ...prev, open: false };
    });
  };

  const submitProposal = useCallback(() => {
    if (
      !qClient ||
      !contractInfo.governorAddress ||
      contractInfo.abi.length === 0
    )
      return;

    const qContract = getContract({
      client: qClient,
      // @ts-ignore
      address: contractInfo.governorAddress,
      abi: contractInfo.abi,
    });

    // @ts-ignore
    qContract.write
      .propose([
        getProposalTargetsForSend(proposal),
        getProposalValuesForSend(proposal),
        getProposalCallDatasForSend(proposal),
        JSON.stringify(proposal.description),
      ])
      .then((res: any) => {
        setTxnHash(res);
        setStep(5);
      })
      .catch((err: any) => {
        // alert(err.message);
        setAlertStatus({
          open: true,
          success: false,
          message: err.message,
        });
      });
  }, [contractInfo.abi, contractInfo.governorAddress, qClient, proposal]);

  useEffect(() => {
    // @ts-ignore
    fetchContractInfo(client, params.contractAddress).then((result: any) => {
      if (result.abi) {
        const daoContract = getContract({
          client: client,
          // @ts-ignore
          address: params.contractAddress,
          abi: result.abi,
        });

        // @ts-ignore
        daoContract.read.name([]).then((result: string) => {
          rest("Joseon/Corporation/" + result, "GET", {}).then((res: any) => {
            setContractInfo((ci) => {
              return {
                ...ci,
                corporationId: result,
                name: res.data.Name,
                url: res.data.Url,
                governorAddress: daoContract.address,
                // correct this to get it on-chain
                tokenAddress: res.data.Shares_EVM_Contract?.Contract,
                tokenSupply: res.data.Shares,
                quorum: res.data.Quorum,
                votingDelay: res.data.Voting_Delay,
                votingPeriod: res.data.Voting_Period,
                abi: daoContract.abi,
              };
            });
          });

          setIsLoading(false);
        });
      }
    });
  }, [client, params.contractAddress]);

  useEffect(() => {
    // @ts-ignore
    const walletIsConnected = qClient && qClient.account ? true : false;

    setProposalChecks((pc) => {
      return { ...pc, connected: walletIsConnected };
    });

    if (walletIsConnected) {
      window.ethereum.request({ method: "eth_chainId" }).then((result: any) => {
        setProposalChecks((pc) => {
          return { ...pc, validChain: parseInt(result, 16) === joseon.id };
        });
      });
    }
  }, [qClient, client]);

  let stepComponent;

  switch (step) {
    case 1:
      stepComponent = <ConnectWalletProposal checks={proposalChecks} />;
      break;
    case 2:
      stepComponent = (
        <DescribeProposition
          description={proposal.description}
          titleCallback={handleTitle}
          descCallback={handleDescription}
        />
      );
      break;
    case 3:
      // @ts-ignore
      stepComponent = (
        <CreateProposalActions
          pActions={proposal.actions}
          contractInfo={contractInfo}
          callback={saveAction}
        />
      );
      break;
    case 4:
      stepComponent = (
        <PreviewProposal proposal={proposal} contractInfo={contractInfo} />
      );
      break;
    case 5:
      stepComponent = (
        <CreateProposalSuccess txnHash={txnHash} contractInfo={contractInfo} />
      );
      break;
  }

  if (isLoading) return <FullpageLoader />;

  return (
    <Container className=" pt-16 md:pt-28">
      <div className="container h-full  rounded">
        <div className=" rounded-2xl border border-gray-200 bg-white  py-1 shadow-lg shadow-gray-200/40">
          <div className="flex lg:flex-row flex-col flex-wrap items-center bg-white gap-6 lg:justify-between border-b py-4 px-4 md:p-6 rounded-t-lg  drop-shadow-md ">
            <div className="flex flex-row lg:flex-col justify-between lg:gap-4 w-full lg:w-fit ">
              <h3 className="text-base md:text-xl  whitespace-nowrap text-be-navy-blue-700 font-bold ">
                Create new proposal
              </h3>
              {contractInfo.name}
            </div>
            <StepperNewProposal step={step} />
            <div className="flex items-center gap-3 justify-end md:w-full lg:w-fit ">
              <Button
                color="white"
                label="Cancel"
                size="md"
                className="md:w-fit w-full"
                onClick={() => {
                  navigate("/dao/" + params.contractAddress);
                }}
              />
              {step === 1 ? (
                <></>
              ) : (
                <div className="flex gap-4">
                  <Button
                    size="md"
                    label="Back"
                    color="white"
                    onClick={() => setStep(step - 1)}
                    className="md:w-fit w-full"
                  />
                </div>
              )}
              {step === 4 && (
                <Button
                  size="md"
                  color="red"
                  label="Submit"
                  className="md:w-fit w-full"
                  onClick={submitProposal}
                />
              )}
              {step === 4 ? (
                <></>
              ) : (
                <Button
                  size="md"
                  color={"red"}
                  label="Continue"
                  disabled={
                    !(proposalChecks.connected && proposalChecks.validChain)
                  }
                  onClick={() => setStep(step + 1)}
                  className="md:w-fit w-full"
                />
              )}
            </div>
          </div>
          <div className="max-w-[449px] mx-auto">
            <Alert
              className={`items-start my-9 ${
                alertStatus.open ? "block" : "hidden"
              }`}
              color={alertStatus.success ? "green" : "red"}
              onDismiss={handleAlert}
              withBorderAccent
              icons={
                alertStatus.success ? (
                  <CheckIcon className="w-8 p-2 rounded-lg bg-green-100" />
                ) : (
                  <InformationCircleIcon className="w-8 p-2 rounded-lg bg-red-100" />
                )
              }
            >
              <p className="font-medium text-xs break-all flex items-center">
                {alertStatus.message}
              </p>
            </Alert>
          </div>
          <div className="lg:mx-[200px] mx-4">{stepComponent}</div>
        </div>
      </div>
    </Container>
  );
};

export default CreateProposal;
