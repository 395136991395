import React, { FC, useState } from "react";
import { Modal } from "flowbite-react";
import Button from "../common/Button/Button";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

interface ReportBugModalProps {
  visible: boolean;
  onClose: () => void;
}

const ReportBugModal: FC<ReportBugModalProps> = ({ visible, onClose }) => {
  const [messageSent, setMessageSent] = useState(false);

  const handleSubmit = (event: any) => {
    event?.preventDefault();
    setMessageSent(true);
  };

  const formHandler = () => {
    onClose();
    setMessageSent(false);
  };

  return (
    <Modal show={visible} size={"md"}>
      <Modal.Body>
        <button
          className="absolute top-6 right-6 group flex items-center justify-center w-8 h-8 rounded-lg bg-be-navy-blue-50 border border-be-navy-blue-100 hover:bg-be-navy-blue-100"
          onClick={formHandler}
        >
          <XMarkIcon className="w-6 text-be-navy-blue-700" />
        </button>
        {messageSent ? (
          <div className="flex flex-col items-center justify-center py-56">
            <CheckCircleIcon className="w-20 h-20 text-green-500 mx-auto mb-6" />
            <h2 className="text-4xl text-be-navy-blue-700 font-bold mb-4 text-center">
              Your bug report has been received
            </h2>
            <p className="text-be-navy-blue-500 text-center">
              Our team is on it, working hard to fix the issue. Thanks for
              helping us improve!
            </p>
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center w-full mb-9">
              <h2 className="text-2xl font-bold text-be-navy-blue-700 mt-9">
                Report a bug
              </h2>
              <p className="max-w-[259px] mt-4 w-full text-center text-gray-500 text-sm font-normal">
                If you don’t have a wallet, you can select a provider and create
                one now.
              </p>
            </div>
            <hr className="w-full h-[1px] bg-be-navy-blue-100 mb-9" />
            <form action="" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-2 mb-4">
                <label
                  htmlFor="full-name"
                  className="text-sm text-be-navy-blue-700 font-medium"
                >
                  Full Name
                </label>
                <input
                  className="w-full h-12 py-3 px-4 border border-be-navy-blue-100 outline-none ring-transparent ring-0 focus:ring-be-navy-blue-300 rounded bg-be-navy-blue-50 text-gray-500 font-medium text-sm"
                  type="text"
                  id="full-name"
                  placeholder="Full Name"
                />
              </div>
              <div className="flex flex-col gap-2 mb-4">
                <label
                  htmlFor="email"
                  className="text-sm text-be-navy-blue-700 font-medium"
                >
                  Email
                </label>
                <input
                  className="w-full h-12 py-3 px-4 border border-be-navy-blue-100 outline-none ring-transparent ring-0 focus:ring-be-navy-blue-300 rounded bg-be-navy-blue-50 text-gray-500 font-medium text-sm"
                  type="text"
                  id="email"
                  placeholder="email@example.com"
                />
              </div>
              <div className="flex flex-col gap-2 mb-6">
                <label
                  htmlFor="message"
                  className="text-sm text-be-navy-blue-700 font-medium"
                >
                  Message
                </label>
                <textarea
                  className="w-full h-32 py-3 px-4 border border-be-navy-blue-100 outline-none ring-transparent ring-0 focus:ring-be-navy-blue-300 rounded bg-be-navy-blue-50 text-gray-500 font-medium text-sm"
                  id="message"
                  placeholder="Type something"
                />
              </div>
              <Button type="submit" label="Submit" color="red" fullSized />
            </form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ReportBugModal;
