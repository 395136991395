// @ts-nocheck
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useConfig } from "wagmi";
import { getBlock } from "wagmi/actions";

import ConsensInfoTab from "../../features/common/BlockTabs/ConsensInfoTab/ConsensInfoTab";
import OverviewTab from "../../features/common/BlockTabs/OverviewTab/OverviewTab";
import BlockTransactionsTab from "../../features/common/BlockTabs/BlockTransactionsTab/BlockTransactionsTab";
import BeTab from "../../features/common/BlockTabs/BlockTabs";
import FullpageLoader from "../../features/common/FullpageLoader/FullpageLoader";

const TransactionInfoPage: FC = () => {
  const params = useParams();
  const config = useConfig();
  const [blockData, setBlockData] = useState(null);
  const [blockDataLoading, setBlockDataLoading] = useState(true);

  const fetchBlockData = async (blockNumber) => {
    const q = {
      includeTransactions: true,
    };

    if (blockNumber.length === 66) {
      q.blockHash = blockNumber;
    } else {
      q.blockNumber = BigInt(blockNumber);
    }

    const data = await getBlock(config, q);

    setBlockData(data);
    setBlockDataLoading(false);
  };

  useEffect(() => {
    fetchBlockData(params.blockNumber);
  }, [params.blockNumber]); // eslint-disable-line

  if (blockDataLoading) {
    return <FullpageLoader />;
  } else {
    const tabItems = [
      {
        title: "Overview",
        content: <OverviewTab data={blockData} />,
      },
      {
        title: "Details",
        content: <ConsensInfoTab data={blockData} />,
      },
      {
        title: "Transactions",
        content: <BlockTransactionsTab data={blockData.transactions} />,
      },
    ];

    return (
      <section className="bg-be-navy-blue-50 min-h-screen">
        <div className="container space-y-3 py-8 h-full">
          <div className="border-b py-4 flex items-center space-x-4">
            <p className="font-bold text-xl text-be-navy-blue-700">Block</p>
            <span className="font-medium text-base text-be-navy-blue-300">
              #{blockData.number.toString()}
            </span>
            {/**
            <div className=" flex space-x-1">
              <Button
                size="xs"
                color="lightRedTransparent"
                icon={<img src={iconLeftArrow} alt="card info icon" />}
              />
              <Button
                size="xs"
                color="lightRedTransparent"
                icon={<img src={iconRightArrow} alt="right arrow icon" />}
              />
            </div>**/}
          </div>
          <BeTab tabItems={tabItems} />
        </div>
      </section>
    );
  }
};

export default TransactionInfoPage;
