import {
  getAbiItem,
  decodeFunctionData,
  decodeEventLog,
  getContract,
} from "viem";

export const fetchDAOContractInfo = async (client: any, hash: string) => {
  try {
    const data = await client.request({
      // @ts-ignore
      method: "modchain_contractInfo",
      // @ts-ignore
      params: [hash],
    });

    if (data.abi) {
      const abiItem = getAbiItem({
        abi: data.abi,
        name: "setVotingPeriod",
      });

      if (abiItem) {
        return data;
      } else {
        throw { name: "not_valid", message: "Not a compatible DAO." }; // eslint-disable-line
      }
    }
  } catch (e: any) {
    if (e.name === "not_valid") {
      throw e;
    } else {
      throw Error("No contract recognized on this address.");
    }
  }
};

export const fetchContractInfo = async (client: any, hash: string) => {
  try {
    var data = await client.request({
      // @ts-ignore
      method: "modchain_contractInfo",
      // @ts-ignore
      params: [hash],
    });
  } catch {
    return null;
  }

  return data;
};

export const fetchTransactionInfo = async (
  client: any,
  hash: string,
  decode = true,
) => {
  try {
    var data = await client.request({
      method: "modchain_getTransactionInfo",
      // @ts-ignore
      params: [hash],
    });
  } catch {
    throw Error("Cannot fetch transaction.");
  }

  data.tx.ctCreation = data.receipt.contractAddress;

  if (decode && data.abi) {
    try {
      var decodedInput = decodeFunctionData({
        abi: data.abi,
        data: data.tx.input,
      });
    } catch {
      // @ts-expect-error
      decodedInput = false;
    }

    const isolatedAbi = getAbiItem({
      abi: data.abi,
      name: decodedInput.functionName,
    });

    // @ts-expect-error
    data.tx.function = isolatedAbi ? isolatedAbi.abi : null;
    data.tx.decodedInput = decodedInput;

    if (decodedInput && decodedInput.functionName === "transfer") {
      const contract = getContract({
        address: data.tx.to,
        client: client,
        abi: [
          {
            abi: "function decimals() returns (uint8)",
            compact: "decimals()",
            inputs: [],
            name: "decimals",
            outputs: [
              {
                internalType: "uint8",
                name: "",
                type: "uint8",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
        ],
      });

      // @ts-expect-error
      const decimals = await contract.read.decimals();

      const internalTransfer = {
        contract: data.tx.to,
        decimals: decimals,
        from: data.tx.from,
        to: decodedInput.args[0],
        amount: decodedInput.args[1],
      };

      data.tx.internalTransfer = internalTransfer;
    }

    data.receipt.logs.forEach((log: any) => {
      const decodedLog = decodeEventLog({
        abi: data.abi,
        data: log.data,
        topics: log.topics,
      });

      log.decoded = decodedLog;
    });
  } else {
    data.tx.decodedInput = false;
  }

  return data;
};

export const fetchContractLogs = async (
  client: any,
  hash: string,
  filter: string = "",
) => {
  const data = await client.request({
    // @ts-ignore
    method: "modchain_lookupLogsByAddress",
    // @ts-ignore
    params: [hash + filter],
  });

  return data;
};
