import React from "react";
import { CustomFlowbiteTheme, FloatingLabel } from "flowbite-react";

interface ContractInputProps {
  abiInput: {
    internalType: string;
    name: string;
    type: string;
  };
}

const floatingLabelTheme: CustomFlowbiteTheme["floatingLabel"] = {
  input: {
    default: {
      filled: {
        md: "peer block w-full appearance-none border-0 border-b-[1px] border-be-navy-blue-100 py-3 px-2 text-sm text-gray-700 hover:border-blue-300 focus:border-blue-500 focus:outline-none focus:ring-0",
      },
    },
  },
  label: {
    default: {
      filled: {
        md: "absolute left-2.5 top-4 z-10 origin-[0] -translate-y-6 scale-75 transition-transform text-sm text-blue-500 peer-placeholder-shown:text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500",
      },
    },
  },
};

const ContractInput: React.FC<ContractInputProps> = ({ abiInput }) => {
  return (
    // this should be an <input name=abiInput.name with placeholder abiInput.name (abiInput.type)
    <>
      <FloatingLabel
        variant="filled"
        name={abiInput.name}
        theme={floatingLabelTheme}
        label={abiInput.name + "(" + abiInput.type + ")"}
      />
    </>
  );
};

export default ContractInput;
