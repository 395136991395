import React, { FC } from "react";
import Badge from "../Badge/Badge";

interface StatusBadgeProps {
  startTimestamp: number | bigint;
  endTimestamp: number | bigint;
}

const StatusBadge: FC<StatusBadgeProps> = ({
  startTimestamp,
  endTimestamp,
}) => {
  const currentTimestamp = Date.now();

  if (currentTimestamp < startTimestamp) {
    return (
      <Badge
        color="info"
        size="md"
        status="Not started"
        className="flex-none w-auto"
      />
    );
  } else if (currentTimestamp > endTimestamp) {
    return (
      <Badge
        color="failure"
        size="md"
        status="Finished"
        className="flex-none w-auto"
      />
    );
  } else if (
    currentTimestamp > startTimestamp &&
    currentTimestamp < endTimestamp
  ) {
    return (
      <Badge
        color="success"
        size="md"
        status="In progress"
        className="flex-none w-auto"
      />
    );
  }
};

export default StatusBadge;
