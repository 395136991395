import React, { FC, useState } from "react";
import { BugAntIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "flowbite-react";

import ReportBugModal from "./ReportBugModal";

interface ReportBugProps {
  small?: boolean;
}

const ReportBug: FC<ReportBugProps> = ({ small = true }) => {
  const [visible, setVisible] = useState(false);
  const toggleModal = () => {
    setVisible((visible) => !visible);
  };
  return (
    <div
      className={
        "bg-gray-50 flex items-center gap-4 mr-auto " +
        (!small
          ? "xl:py-1 xl:px-3 xl:max-w-[420px] xl:w-full xl:border border-be-navy-blue-100 xl:rounded"
          : "")
      }
    >
      {!small && (
        <p className="text-xs text-be-navy-blue-500 hidden xl:block">
          Hey! The Explorer is currently in alpha version. If you find any odd
          behavior or bug, you can help us by making a bug report using the
          following button.
        </p>
      )}
      <Tooltip
        style="light" // eslint-disable-line
        content="Report a bug"
        className="tooltip bg-white !border-none !drop-shadow-2xl !rounded !text-sm !text-be-navy-blue-700 !font-medium !top-20 p-2"
      >
        <button
          className="group flex items-center justify-center rounded-lg bg-be-navy-blue-50 border border-be-navy-blue-100 w-8 h-8 transition-all duration-300 hover:bg-red-100"
          onClick={toggleModal}
        >
          <BugAntIcon className="w-4 text-red-500" />
        </button>
      </Tooltip>
      <ReportBugModal visible={visible} onClose={toggleModal} />
    </div>
  );
};

export default ReportBug;
