const formatTxBlockToTxs = (txBlocks: any) => {
  const txs: Array<object> = [];

  txBlocks.map((block: any) => {
    const tx = block.data;
    tx.tx = block.tx;
    tx.block = block.blk;

    txs.push(block.data);

    return null;
  });

  return txs;
};

export default formatTxBlockToTxs;
