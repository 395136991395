import { FC } from "react";
import { useLinkClickHandler } from "react-router-dom";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { copyToClipboard } from "../../../helpers/copyToClipboard";

interface InternalLinkProps {
  blockchainRef: string;
  type: string;
  shorten?: boolean;
  clipboard?: boolean;
  maxLength?: number;
}

const typeMap: { [char: string]: string } = {
  block: "/block/",
  tx: "/tx/",
  address: "/address/",
};

const InternalLink: FC<InternalLinkProps> = ({
  blockchainRef,
  type,
  shorten = true,
  clipboard = true,
  maxLength = 14,
}) => {
  const formatBcRef = (blockchainRef: string) => {
    if (!blockchainRef) return "";

    if (!shorten || blockchainRef.length < maxLength) return blockchainRef;

    let start = blockchainRef.substr(0, maxLength / 2 - 1);
    let end = blockchainRef.substr(-Math.round(maxLength - 1) / 2);

    return start + "⋯" + end;
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(blockchainRef);
  };

  return (
    <>
      <a
        href={typeMap[type] + blockchainRef}
        className="text-blue-500 font-bold"
        onClick={useLinkClickHandler(typeMap[type] + blockchainRef)}
      >
        {formatBcRef(blockchainRef)}
      </a>
      {clipboard ? (
        <button
          className="cursor-pointer text-be-navy-blue-100"
          onClick={handleCopyToClipboard}
        >
          <DocumentDuplicateIcon className="text-be-navy-blue-100 w-4 h-4 hover:text-be-navy-blue-700" />
        </button>
      ) : null}
    </>
  );
};

export default InternalLink;
