import { FC } from "react";
import { Flowbite, Badge, CustomFlowbiteTheme } from "flowbite-react";
import { formatUnits } from "viem";

import BlocksDetailsTimestamp from "../../../BlocksDetailsTimestamp/BlocksDetailsTimestamp";
import { TransactionData } from "../../../../assets/Data/data";
import InternalLink from "../../InternalLink/InternalLink";
import MonospacedDataBlock from "../../BlockchainData/MonospacedDataBlock/MonospacedDataBlock";
import { joseon } from "../../../../wagmi/joseon";

import iconAdjustments from "../../../../assets/icons/iconAdjustments.svg";

// @ts-ignore
const TransactionOverviewTab: FC = ({ data, block }) => {
  const contentTabTheme: CustomFlowbiteTheme = {
    badge: {
      root: {
        base: "flex h-fit items-center justify-center gap-1 font-semibold",
        color: {
          success:
            "text-xs font-bold text-green-500 border-2 border-green-300 bg-green-100 px-2 ",
        },
        size: {
          sm: "px-2",
        },
      },
    },
    progress: {
      base: "w-full bg-blue-200 rounded-full",
    },
  };

  const tabItems = [
    {
      steps: [
        {
          title: "Transaction Hash:",
          content: (
            <div className="flex w-full">
              <InternalLink
                blockchainRef={data.hash}
                type="tx"
                shorten={false}
              />
            </div>
          ),
        },
        {
          title: "Status:",
          content: (
            <div className="col-span-2 flex ">
              <div className="col-auto">
                <Badge color="success" size="sm">
                  Success
                </Badge>
              </div>
            </div>
          ),
        },
        {
          title: "Block:",
          content: (
            <div className="col-span-2">
              <span className=" flex gap-1 items-center font-sans font-medium w-full text-be-navy-blue-700">
                <img src={iconAdjustments} alt="adjustments icon" />
                <InternalLink
                  blockchainRef={parseInt(data.blockNumber, 16).toString()}
                  type="block"
                  shorten={false}
                />
                <span className="hidden border border-be-navy-blue-700 px-1 font-bold rounded text-xs">
                  10 Block Confirmation
                </span>
              </span>
            </div>
          ),
        },
        {
          title: "Timestamp:",
          content: <BlocksDetailsTimestamp time={block.timestamp} />,
        },
      ],
    },

    {
      steps: [
        {
          title: "From:",
          content: (
            <div className="w-full">
              <InternalLink
                blockchainRef={data.from}
                type="address"
                shorten={false}
              />
            </div>
          ),
        },
        {
          title: "To:",
          content: data.to ? (
            <div className="w-full">
              <InternalLink
                blockchainRef={data.to}
                type="address"
                shorten={false}
              />
            </div>
          ) : data.ctCreation ? (
            <div className="w-full">
              New Contract
              <br />
              <InternalLink
                blockchainRef={data.ctCreation}
                type="address"
                shorten={false}
              />
            </div>
          ) : (
            "undefined"
          ),
        },
      ],
    },
    {
      steps: [
        {
          title: "Value:",
          content: !data.internalTransfer ? (
            <div className="col-span-2 flex items-center ">
              <span className="mr-1">
                {formatUnits(
                  BigInt(data.value),
                  joseon.nativeCurrency.decimals,
                )}
              </span>
              <span className="hidden text-be-navy-blue-300">
                (${TransactionData.price})
              </span>
            </div>
          ) : (
            <div className="col-span-2 flex items-center ">
              <span className="mr-1">
                Transfer on{" "}
                <InternalLink
                  blockchainRef={data.internalTransfer.contract}
                  type="address"
                />{" "}
                From{" "}
                <InternalLink
                  blockchainRef={data.internalTransfer.from}
                  type="address"
                />{" "}
                to{" "}
                <InternalLink
                  blockchainRef={data.internalTransfer.to}
                  type="address"
                />
                <br />
                Amount:{" "}
                {formatUnits(
                  BigInt(data.internalTransfer.amount),
                  data.internalTransfer.decimals,
                )}
              </span>
            </div>
          ),
        },
        {
          title: "Data:",
          content: (
            <MonospacedDataBlock contents={data.input} extended={true} />
          ),
        },
        ...(data.decodedInput
          ? [
              {
                title: "Function:",
                content:
                  data.function !== false ? (
                    <div className="w-full">
                      <MonospacedDataBlock
                        contents={data.function}
                        extended={false}
                      />
                    </div>
                  ) : (
                    "Missing ABI"
                  ),
              },
              {
                title: "Decoded Data:",
                content: (
                  <MonospacedDataBlock
                    contents={
                      "Function Name:\n" +
                      data.decodedInput.functionName +
                      "\n\n" +
                      "[" +
                      data.decodedInput.args.toString() +
                      "]"
                    }
                    extended={true}
                  />
                ),
              },
            ]
          : []),
        {
          title: "Gas:",
          content: (
            <div className="col-span-2 flex items-center ">
              <span className="mr-1">{parseInt(data.gas, 16).toString()}</span>
              <span className="hidden text-be-navy-blue-300">($0.89)</span>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <Flowbite theme={{ theme: contentTabTheme }}>
      <div className="bg-white border border-gray-300 rounded-xl w-full h-full">
        {tabItems.map((item, itemIndex) => (
          <div
            key={itemIndex}
            className={`p-4 grid grid-cols-1 gap-y-6 md:py-8 md:px-6 py-4 px-3 ${
              itemIndex === tabItems.length - 1
                ? ""
                : "border-b border-gray-300"
            }`}
          >
            {item.steps.map((step, index) => (
              <div
                key={index}
                className="flex flex-col md:grid md:grid-cols-3 gap-2 items-start justify-between"
              >
                <div className="flex items-center space-x-2 col-span-1">
                  <span className="text-be-navy-blue-300 font-sans font-medium whitespace-nowrap">
                    {step.title}
                  </span>
                </div>
                <span className="text-be-navy-blue-700 w-full md:ml-2 col-span-2">
                  {step.content}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Flowbite>
  );
};

export default TransactionOverviewTab;
