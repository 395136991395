import { FC } from "react";

interface FormattedTopicsProps {
  topics: Array<string>;
}

const FormattedTopics: FC<FormattedTopicsProps> = ({ topics }) => {
  if (!topics) return;

  return (
    <table className="rounded-xl overflow-hidden bg-gray-100 w-full">
      <tbody className="flex flex-col relative">
        {topics.map((item, index) => (
          <tr key={index} className="relative z-20">
            <td className={"font-medium px-4 py-2 bg-be-navy-blue-300"}>
              {index.toString()}
            </td>
            <td
              className={
                "font-mono text-sm px-3 w-full " +
                (index === topics.length - 1 ? "" : "border-b border-gray-300")
              }
            >
              <div className="break-all md:break-normal">{item}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default FormattedTopics;
