import { Label, Textarea } from "flowbite-react";

import TextInput from "../../common/TextInput/TextInput";

// @ts-ignore
const DescribeProposition = ({ description, titleCallback, descCallback }) => {
  return (
    <div className="md:px-4 md:py-16 py-5 px-0 space-y-4 md:space-y-9">
      <p className="text-be-navy-blue-500">
        Please enter a name and a description. These will be public and shown on
        the proposal.
        <br />
        Do note that this is stored on-chain, capped to 20,000 chars for
        title+description. Longer descriptions will increase the gas fee.
      </p>
      <TextInput
        label="Name"
        placeholder="Title of the proposal"
        onChange={titleCallback}
        value={description.title}
      />
      <div className="mt-2">
        <Label
          className="dark:text-white text-base text-be-navy-700 font-bold"
          htmlFor="description"
        >
          Description
        </Label>
        <Textarea
          id="description"
          placeholder="Describe your proposal"
          required
          rows={6}
          className="bg-white !mt-2 !border !border-be-navy-blue-100"
          onChange={descCallback}
          value={description.description}
        />
      </div>
    </div>
  );
};

export default DescribeProposition;
