import { FC } from "react";
import { Flowbite, CustomFlowbiteTheme } from "flowbite-react";

import FormattedTopics from "../../BlockchainData/FormattedTopics/FormattedTopics";
import InternalLink from "../../InternalLink/InternalLink";

import MonospacedDataBlock from "../../BlockchainData/MonospacedDataBlock/MonospacedDataBlock";

// @ts-ignore
const TransactionLogsTab: FC = ({ receipt, decodedData }) => {
  const contentTabTheme: CustomFlowbiteTheme = {
    badge: {
      root: {
        base: "flex h-fit items-center justify-center gap-1 font-semibold",
        color: {
          success:
            "text-xs font-bold text-green-500 border-2 border-green-300 bg-green-100 px-2 ",
        },
        size: {
          sm: "px-2",
        },
      },
    },
    progress: {
      base: "w-full bg-blue-200 rounded-full",
    },
  };

  const tabItems = receipt.logs.map((log: any, index: number) => {
    return {
      steps: [
        {
          title: "Index:",
          content: (
            <div className="w-full">
              <span className="font-sans font-medium text-be-navy-blue-700 break-words">
                {index}
              </span>
            </div>
          ),
        },
        {
          title: "Address:",
          content: (
            <div className="w-full break-all">
              <InternalLink
                blockchainRef={log.address}
                type="address"
                shorten={false}
              />
            </div>
          ),
        },
        {
          title: "Topics:",
          content: <FormattedTopics topics={log.topics} />,
        },
        {
          title: "Data:",
          content: (
            <MonospacedDataBlock
              contents={log.data.toString()}
              extended={true}
            />
          ),
        },
        ...(log.decoded
          ? [
              {
                title: "Decoded Event:",
                content: (
                  <MonospacedDataBlock
                    contents={
                      "Event Name:\n" +
                      log.decoded.eventName +
                      "\n\n" +
                      JSON.stringify(log.decoded.args, (key, val) =>
                        typeof val === "bigint" ? val.toString() : val,
                      )
                    }
                    extended={true}
                  />
                ),
              },
            ]
          : []),
      ],
    };
  });

  return (
    <Flowbite theme={{ theme: contentTabTheme }}>
      <div className="bg-white border border-gray-300 rounded-xl w-full h-full overflow-x-auto">
        {tabItems.map((item: any, itemIndex: number) => (
          <div
            key={itemIndex}
            className={`p-4 grid grid-cols-1 gap-y-6 md:py-8 md:px-6 py-4 px-3 overflow-x-auto ${
              itemIndex === tabItems.length - 1
                ? ""
                : "border-b border-gray-300"
            }`}
          >
            {item.steps.map((step: any, index: number) => (
              <div
                key={index}
                className="flex flex-col md:grid md:grid-cols-3 gap-2 items-start justify-between"
              >
                <div className="flex items-center space-x-2 col-span-1">
                  <span className="text-be-navy-blue-300 font-sans font-medium whitespace-nowrap">
                    {step.title}
                  </span>
                </div>
                <span className="text-be-navy-blue-700 w-full md:ml-2 col-span-2">
                  {step.content}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Flowbite>
  );
};

export default TransactionLogsTab;
