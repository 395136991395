import { useState } from "react";
import { useRecoilValue } from "recoil";
import { CustomFlowbiteTheme, Label, Modal, Textarea } from "flowbite-react";

import Button from "../common/Button/Button";
import InternalLink from "../common/InternalLink/InternalLink";
import DAOVoteChoices from "./DAOVoteChoices/DAOVoteChoices";
import GlobalClient from "../../recoil/GlobalClient";

import {
  CheckIcon,
  InformationCircleIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import Alert from "../common/Alert/Alert";

const modalTheme: CustomFlowbiteTheme["modal"] = {
  root: {
    base: "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
    show: {
      on: "flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80",
      off: "hidden",
    },
    sizes: {
      sm: "max-w-sm",
      md: "max-w-[467px]",
      lg: "max-w-lg",
      xl: "max-w-xl",
      "2xl": "max-w-2xl",
      "3xl": "max-w-3xl",
      "4xl": "max-w-4xl",
      "5xl": "max-w-5xl",
      "6xl": "max-w-6xl",
      "7xl": "max-w-7xl",
    },
  },
  content: {
    base: "relative h-full w-full p-4 md:h-auto",
    inner:
      "relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col w-full ",
  },
  body: {
    base: "p-6 flex-1 overflow-auto w-full",
    popup: "pt-0",
  },
  header: {
    base: "flex items-center justify-center rounded-t p-5",
    popup: "p-2 border-b-0",
    title: "text-2xl font-bold text-be-navy-blue-700 text-center w-full",
    close: {
      base: "ml-auto inline-flex items-center border border-be-navy-blue-100 rounded-lg bg-be-navy-blue-50 p-1.5 text-sm text-gray-700 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
      icon: "h-5 w-5",
    },
  },
  footer: {
    base: "flex items-center space-x-2 rounded-b border-gray-200 p-6 dark:border-gray-600",
    popup: "border-t",
  },
};

const DAOVoteModal = ({
  // @ts-expect-error
  proposalInfo,
  // @ts-expect-error
  status,
  // @ts-expect-error
  setOpen,
  // @ts-expect-error
  callback,
  // @ts-expect-error
  votingPowerCallback,
}) => {
  const qClient = useRecoilValue(GlobalClient);

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [votingPower, setVotingPower] = useState("fetching...");
  const [support, setSupport] = useState(null);
  const [reason, setReason] = useState(null);

  const handleOpenModal = () => {
    setSupport(null);
    setReason(null);
    setOpenModal(true);
    setIsLoading(false);
    votingPowerCallback().then((power: any) => {
      if (power) {
        setVotingPower(power);
      } else {
        setVotingPower("Unknown");
      }
    });
  };

  const handleAlert = () => {
    setOpen((prev: any) => {
      return { ...prev, open: false };
    });
  };

  const closeModal = () => {
    setOpen((prev: any) => {
      return { ...prev, open: false };
    });
    setOpenModal(false);
  };

  const handleSupportChange = (support: number) => {
    // @ts-expect-error
    setSupport(support);
  };

  const handleReasonChange = (e: any) => {
    setReason(e.target.value);
  };

  const handleSubmit = () => {
    callback(Number(support), reason);
  };

  // @TODO throw error because not connected
  // @ts-expect-error
  if (!qClient.account) return;

  return (
    <>
      <Button
        color="red"
        icon={<WalletIcon className="h-4 w-4" />}
        label="Vote on-chain"
        onClick={handleOpenModal}
      />
      <Modal
        show={openModal}
        onClose={closeModal}
        size="md"
        theme={modalTheme}
        className="flex items-center justify-center"
      >
        <Modal.Header>Voting</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div className="flex gap-2">
              Voting with{" "}
              <InternalLink
                // @ts-expect-error
                blockchainRef={qClient.account.address}
                type="address"
                clipboard={false}
              />
            </div>
            <div className="rounded-lg border border-gray-300 py-4 px-6">
              <h3 className="uppercase font-normal text-xs text-gray-500 md:mb-4">
                Voting Power
              </h3>
              <div className="flex items-center justify-between">
                <span className="text-be-navy-blue-500 text-4xl font-medium">
                  {votingPower}
                </span>
                <InformationCircleIcon className="w-6 h-6 text-gray-500" />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold text-be-navy-blue-700 md:mb-4 capitalize">
                {proposalInfo.description.title}
              </h3>
              <Alert
                className={`items-start my-9 ${
                  status.open ? "block" : "hidden"
                }`}
                color={status.success ? "green" : "red"}
                onDismiss={handleAlert}
                withBorderAccent
                icons={
                  status.success ? (
                    <CheckIcon className="w-8 p-2 rounded-lg bg-green-100" />
                  ) : (
                    <InformationCircleIcon className="w-8 p-2 rounded-lg bg-red-100" />
                  )
                }
              >
                <p className="font-medium text-xs break-all">
                  {status.message}
                </p>
              </Alert>
              <DAOVoteChoices
                support={support}
                callback={handleSupportChange}
              />
              <div className="max-w-full">
                <div className="mb-2 block">
                  <Label
                    htmlFor="comment"
                    value="Reason (optional)"
                    className="mb-4 font-bold text-be-navy-blue-700"
                  />
                </div>
                <Textarea
                  id="comment"
                  placeholder="You can write a comment to send along your vote"
                  rows={4}
                  className="bg-white border-be-navy-blue-100"
                  onChange={handleReasonChange}
                />
              </div>
            </div>
            <Button
              color="red"
              label="Submit"
              className="w-full"
              disabled={isLoading}
              onClick={handleSubmit}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DAOVoteModal;
