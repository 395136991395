import React from "react";

interface ContractOutputProps {
  abiOutput: {
    internalType: string;
    name: string;
    type: string;
  };
}

const ContractOutput: React.FC<ContractOutputProps> = ({ abiOutput }) => {
  return (
    <>
      <h2 className="italic font-mono text-sm text-gray-500">
        <strong className="not-italic text-gray-600">{abiOutput.name}:</strong>{" "}
        {abiOutput.type}
      </h2>
    </>
  );
};

export default ContractOutput;
