import React, { FC } from "react";
import { Table } from "flowbite-react";

import InternalLink from "../../common/InternalLink/InternalLink";
import AddressFieldComponent from "../../common/AddressFieldComponent/AddressFieldComponent";
import {
  formatMobileTimestamp,
  formatTimestamp,
} from "../../../helpers/formatTimestamp";

interface BlockTableRowProps {
  data: any;
}

const BlockTableRow: FC<BlockTableRowProps> = ({ data }) => {
  return (
    <Table.Row className="!bg-white border-b border-gray-100 space-x-3">
      <Table.Cell className="text-blue-500 font-medium min-w-20 md:min-w-0">
        <InternalLink
          blockchainRef={parseInt(data.number, 16).toString()}
          type="block"
          clipboard={false}
        />
      </Table.Cell>
      <Table.Cell className="min-w-16 md:min-w-0 px-0">
        <span className="text-be-navy-blue-700 font-medium">
          <span className="hidden md:block">
            {formatTimestamp(parseInt(data.timestamp, 16))}
          </span>
          <span className="block md:hidden">
            {formatMobileTimestamp(parseInt(data.timestamp, 16))}
          </span>
        </span>
      </Table.Cell>
      <Table.Cell className="min-w-28 md:min-w-0">
        <span className="text-blue-500 font-bold">
          {data.transactions.length.toString()} txs
        </span>
      </Table.Cell>
      <Table.Cell className="text-blue-500">
        <AddressFieldComponent address={data.miner} />
      </Table.Cell>
      <Table.Cell className="font-medium ">
        <div className="flex gap-1 ">
          <span>{parseInt(data.gasUsed, 16).toString()}</span>
          <span className="text-be-navy-blue-300">
            ({(parseInt(data.gasUsed, 16) / parseInt(data.gasLimit, 16)) * 100}
            %)
          </span>
        </div>
        <div className="bg-blue-200 h-1">
          <hr
            className={"h-full bg-blue-500"}
            style={{
              width:
                (
                  (parseInt(data.gasUsed, 16) / parseInt(data.gasLimit, 16)) *
                  100
                ).toString() + "%",
            }}
          ></hr>
        </div>
      </Table.Cell>
      <Table.Cell>
        <span className="text-be-navy-blue-700">
          {parseInt(data.gasLimit, 16).toString()}
        </span>
      </Table.Cell>
    </Table.Row>
  );
};

export default BlockTableRow;
