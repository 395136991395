import { FC } from "react";
import { Table } from "flowbite-react";
import { useNavigate } from "react-router-dom";

import StatusBadge from "../common/StatusBadge/StatusBadge";

// @ts-ignore
const DAOTable: FC = ({ proposal }) => {
  const navigate = useNavigate();
  const startTimestamp = proposal.voteStart * 1000;
  const endTimestamp = proposal.voteEnd * 1000;

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  return (
    <Table.Row
      className="font-semibold cursor-pointer"
      onClick={() =>
        navigate(
          "/dao/" + proposal.governorAddress + "/proposal/" + proposal.tx,
        )
      }
    >
      <Table.Cell>
        <div className="flex gap-2">
          <div className="flex-auto">{proposal.title}</div>
          <StatusBadge
            startTimestamp={startTimestamp}
            endTimestamp={endTimestamp}
          />
        </div>
      </Table.Cell>
      <Table.Cell className="min-w-56 md:min-w-0">
        {formatDate(proposal.voteStart)}
      </Table.Cell>
      <Table.Cell className="min-w-56 md:min-w-0">
        {formatDate(proposal.voteEnd)}
      </Table.Cell>
      <Table.Cell>
        {proposal.votes.for} / {proposal.votes.against} /{" "}
        {proposal.votes.abstain}
      </Table.Cell>
      <Table.Cell>100</Table.Cell>
    </Table.Row>
  );
};

export default DAOTable;
