import React from "react";
import { formatDistanceToNow } from "date-fns";

interface BlocksDetailsTimestampProps {
  time: bigint;
}

const BlocksDetailsTimestamp: React.FC<BlocksDetailsTimestampProps> = ({
  time,
}) => {
  const timestamp = Number(time) * 1000;
  const givenTime = new Date(Number(time) * 1000);
  const distance = formatDistanceToNow(timestamp, { addSuffix: true });
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const addLeadingZero = (value: number) =>
    value < 10 ? `0${value}` : `${value}`;
  const formattedUtcTime = `(${
    months[givenTime.getUTCMonth()]
  }-${addLeadingZero(
    givenTime.getUTCDate(),
  )}-${givenTime.getUTCFullYear()} ${addLeadingZero(
    givenTime.getUTCHours(),
  )}:${addLeadingZero(givenTime.getUTCMinutes())}:${addLeadingZero(
    givenTime.getUTCSeconds(),
  )} ${givenTime.getUTCHours() >= 12 ? "PM" : "AM"} UTC)`;

  return (
    <div className="col-span-2">
      <span className="font-sans text-be-navy-blue-700 font-medium  break-all">
        {distance} {formattedUtcTime}
      </span>
    </div>
  );
};

export default BlocksDetailsTimestamp;
