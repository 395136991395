import { useConfig } from "wagmi";
import { getAccount } from "@wagmi/core";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import BorderContainer from "../../common/BorderContainer/BorderContainer";
import InternalLink from "../../common/InternalLink/InternalLink";

// @ts-ignore
const PreviewProposal = ({ proposal, contractInfo }) => {
  const account = getAccount(useConfig());

  return (
    <div className="md:px-4 space-y-4 md:space-y-9 max-w-[950px] md:py-16 py-4 px-0 ">
      <h3 className="text-be-navy-blue-500 text-xl font-bold">
        {proposal.description.title}
      </h3>
      <div className="flex items-center gap-4">
        <p className="text-gray-500 text-sm">
          Proposed by
          {/** @ts-ignore **/}
          <InternalLink blockchainRef={account.address} type="address" />
        </p>
      </div>
      <h4 className="text-be-navy-blue-500 text-base font-bold">Description</h4>
      <p className="text-gray-500 text-sm">
        {proposal.description.description}
      </p>
      <h4 className="text-be-navy-blue-500 font-bold">Actions</h4>
      {proposal.actions.map((data: any, index: number) => {
        return (
          <div key={index}>
            <h5 className="text-blue-500 text-base font-bold">
              Action #{index + 1}:
            </h5>
            <BorderContainer className="flex flex-col items-center gap-6 justify-center">
              <span className="text-xs tracking-widest text-be-navy-blue-700 font-bold uppercase">
                Transfer
              </span>
              <div className="flex items-center gap-6 text-be-navy-blue-700 text-3xl font-medium ">
                <span>{data.data.value} JSM</span>
              </div>
              <div className="flex gap-4 items-center">
                {contractInfo.name}{" "}
                <InternalLink
                  blockchainRef={contractInfo.governorAddress}
                  type="address"
                />
                <ArrowRightIcon className=" h-6 w-6 text-gray-500 " />
                <InternalLink blockchainRef={data.data.target} type="address" />
              </div>
            </BorderContainer>
          </div>
        );
      })}
    </div>
  );
};

export default PreviewProposal;
