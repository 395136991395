import React from "react";

import InternalLink from "../common/InternalLink/InternalLink";

import { CheckCircleIcon } from "@heroicons/react/24/outline";

// @ts-ignore
const CreateProposalSuccess = ({ txnHash, contractInfo }) => {
  return (
    <div className="py-14">
      <div className="flex items-center flex-col gap-4">
        <CheckCircleIcon className="h-12 w-12 text-green-500 mx-auto" />
        <h2 className="font-bold text-be-navy-blue-700 text-xl">
          Proposal submitted
        </h2>
        <div className="flex items-center gap-2">
          <span className="text-gray-500 text-sm">
            Transaction Link (wait until the transaction has been approved):
          </span>
          <span>
            <InternalLink blockchainRef={txnHash} type="tx" clipboard={false} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreateProposalSuccess;
