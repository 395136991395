import React from "react";

import { substring } from "../../../helpers/substring";

import trusted_addresses from "../../../assets/trusted_addresses.json";
import trustImg from "../../../assets/images/joseonfav.png";
import { CustomFlowbiteTheme, Tooltip } from "flowbite-react";

interface AddressFieldComponentProps {
  address: string;
}

const tooltipTheme: CustomFlowbiteTheme = {
  tooltip: {
    arrow: {
      style: {
        dark: "bg-be-navy-blue-700",
      },
    },
    style: {
      dark: "bg-be-navy-blue-700 text-white",
    },
  },
};

const AddressFieldComponent: React.FC<AddressFieldComponentProps> = ({
  address,
}) => {
  const trustedAddressIndex = trusted_addresses.trusted.findIndex(
    (trusted) => trusted.address === address,
  );
  const isTrustedAddress = trustedAddressIndex !== -1;

  return isTrustedAddress ? (
    <>
      <Tooltip
        style='dark' // eslint-disable-line
        theme={tooltipTheme.tooltip}
        content={trusted_addresses.trusted[trustedAddressIndex].address}
        className="hidden md:block"
      >
        <div className="flex items-center gap-1 py-[6px] px-2 rounded-lg bg-gray-100 transition-all duration-300 hover:bg-be-navy-blue-100 min-w-40">
          <img className="w-4 h-4" src={trustImg} alt="Address logo" />
          <span className="text-xs md:text-sm text-be-navy-blue-700 font-semibold">
            {trusted_addresses.trusted[trustedAddressIndex].name}
          </span>
        </div>
      </Tooltip>
    </>
  ) : (
    <span className="text-blue-500 font-bold">{substring(address)}</span>
  );
};

export default AddressFieldComponent;
