import { FC, ReactNode } from "react";
import { useLinkClickHandler, useLocation } from "react-router-dom";
import { Navbar, Tooltip } from "flowbite-react";

interface LinkProps {
  to?: string;
  label: string | ReactNode;
  dropdown?: boolean;
  disabled?: boolean;
}

const Link: FC<LinkProps> = ({ to, label, dropdown = false, disabled }) => {
  const location = useLocation();
  const clickHandler = useLinkClickHandler(to || "");

  return (
    <div>
      {dropdown ? (
        <div>{label}</div>
      ) : disabled ? (
        <Tooltip
            style="light" // eslint-disable-line
          content="In Development 👨‍🔬"
          className="tooltip bg-white !border-none !drop-shadow-2xl !rounded !text-sm !text-be-navy-blue-700 !font-medium w-max !top-16 p-2"
        >
          <Navbar.Link
            className="text-gray-300 pointer-events-none text-sm font-medium transition-all duration-300 hover:text-be-navy-blue-100 pl-0 md:pl-3"
            onClick={clickHandler}
            href={to}
            active={location.pathname === to}
          >
            <object>{label}</object>
          </Navbar.Link>
        </Tooltip>
      ) : (
        <Navbar.Link
          className="text-be-navy-blue-700 text-sm font-medium transition-all duration-300 hover:text-be-navy-blue-100 pl-0 md:pl-3"
          onClick={clickHandler}
          href={to}
          active={location.pathname === to}
        >
          <object>{label}</object>
        </Navbar.Link>
      )}
    </div>
  );
};

export default Link;
