import React, { useState, useEffect } from "react";
import {
  Modal,
  Flowbite,
  CustomFlowbiteTheme,
  TextInput,
  Label,
  Radio,
} from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useConfig } from "wagmi";
import { getClient } from "@wagmi/core";
// @ts-ignore
import { rest } from "@karpeleslab/klbfw";
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { fetchDAOContractInfo } from "../../api/contractsApi";
import Button from "../common/Button/Button";
import Loader from "../common/Loader/Loader";

import info from "../../assets/icons/info.svg";

const searchTheme: CustomFlowbiteTheme = {
  textInput: {
    field: {
      input: {
        base: "text-sm placeholder:text-be-navy-blue-300 border !border-be-navy-blue-100 focus:outline-none !rounded-l-lg w-full text-be-navy-blue-700",
        sizes: {
          md: "px-4 py-[11px] w-full max-h-[32px] md:max-h-[40px] text-sm",
        },
        withAddon: {
          off: "rounded-none focus-outline-none ",
        },
      },
    },
  },
  button: {
    base: "group flex items-center whitespace-nowrap  justify-center p-0.5 text-center font-semibold relative  focus:z-10 focus:outline-none border box-border border-be-navy-blue-100 border-r-0",
    inner: {
      base: "flex items-stretch items-center transition-all duration-200 !px-2 focus:ring-0 ",
    },
    color: {
      info: "rounded-r-none bg-be-navy-blue-100 hover:bg-be-navy-blue-100 min-w-[105px] max-h-[32px] md:max-h-[40px]",
    },
    pill: {
      off: "rounded-lg rounded-r-none ",
    },
  },
  modal: {
    root: {
      base: "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
    },
    body: {
      base: "px-4 py-6 md:p-6 flex-1 overflow-auto",
      popup: "pt-0",
    },
    header: {
      close: {
        base: "ml-auto inline-flex items-center transition-all duration-300 rounded-lg bg-transparent p-1.5 text-sm text-gray-700 hover:bg-gray-500 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
        icon: "h-5 w-5",
      },
    },
  },
};

// @ts-ignore
const DAOSearchModal = ({ visible, callback }) => {
  const config = useConfig();
  const client = getClient(config);
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState("");
  const [typeVal, setTypeVal] = useState("corp_id");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [error, setError] = useState("");

  const resetState = () => {
    setSearchVal("");
    setTypeVal("corp_id");
    setIsSearching(false);
    setSearchResult(null);
    setError("");
  };

  const updateSearchVal = (e: any) => {
    setSearchVal(e.target.value);
  };

  const updateTypeVal = (e: any) => {
    setTypeVal(e.target.value);
  };

  const formatResult = (res: any) => {
    return {
      corporationId: res.Public_Id,
      name: res.Name,
      url: res.Url,
      governorAddress: res.DAO_EVM_Contract.Contract,
      tokenAddress: res.Shares_EVM_Contract?.Contract,
      tokenSupply: res.Shares,
      quorum: res.Quorum,
      votingDelay: res.Voting_Delay,
      votingPeriod: res.Voting_Period,
    };
  };

  const handleSearch = () => {
    setIsSearching(true);
    setSearchResult(null);
    setError("");
    switch (typeVal) {
      case "ct_address":
        fetchDAOContractInfo(client, searchVal)
          .then(() => {
            callback();
            navigate("/dao/" + searchVal);
          })
          .catch((e: any) => {
            setError(e.message);
            // @ts-ignore
            setSearchResult(false);
            setIsSearching(false);
          });

        break;
      case "corp_id":
        rest("Joseon/Corporation/" + searchVal, "GET", {})
          .then((res: any) => {
            // @ts-ignore
            setSearchResult(formatResult(res.data));
            setIsSearching(false);
          })
          .catch(() => {
            setError("No Corporation found with the ID " + searchVal + ".");
            // @ts-ignore
            setSearchResult(false);
            setIsSearching(false);
          });
        break;
      case "corp_name":
        rest("Joseon/Corporation:lookup", "GET", {
          name: searchVal,
          _expand:
            "/EVM_Contract/EVM_Chain,/Shares_EVM_Contract/EVM_Chain,/DAO_EVM_Contract/EVM_Chain",
        })
          .then((res: any) => {
            if (res.data.length > 0) {
              // @ts-ignore
              setSearchResult(formatResult(res.data[0]));
            } else {
              // @ts-ignore
              setSearchResult(false);
              setError(
                "No Corporation found with the name " +
                  searchVal +
                  ". Try with 3 characters at least.",
              );
            }

            setIsSearching(false);
          })
          .catch(() => {});
        break;
    }
  };

  useEffect(() => {
    resetState();
  }, [visible]);

  return (
    <Modal show={visible}>
      <Modal.Body className="p-4 md:p-6">
        <div className="flex place-content-end">
          <button
            className="group flex items-center justify-center w-8 h-8 rounded-lg bg-be-navy-blue-50 border border-be-navy-blue-100 hover:bg-be-navy-blue-100"
            onClick={callback}
          >
            <XMarkIcon className="w-6 text-be-navy-blue-700" />
          </button>
        </div>
        <div>
          <h1 className="text-center text-2xl font-bold text-be-navy-blue-700">
            Find a DAO
          </h1>
          <p className="text-center text-sm text-gray-500 mt-4 mb-9 max-w-96 mx-auto">
            You can find a DAO using the Joseon Corporation ID, Corporation Name
            or by using its contract address.
          </p>
          <Flowbite theme={{ theme: searchTheme }}>
            <div className="flex items-center place-content-center">
              <TextInput
                placeholder="Search"
                className="rounded-l-lg py-1 w-full"
                value={searchVal}
                onChange={updateSearchVal}
              />
              <Button
                color="red"
                className="rounded-l-none"
                size="md"
                icon={<MagnifyingGlassIcon className="h-[18px] w-[18px]" />}
                onClick={handleSearch}
              ></Button>
            </div>
            <fieldset className="flex flex-row  gap-4 place-content-center md:gap-9 mt-4 mb-6">
              <div className="flex items-center gap-4">
                <Radio
                  id="corp_id"
                  name="type"
                  value="corp_id"
                  className="border border-gray-300 !ring-0 !ring-transparent focus:!ring-0 focus:!ring-transparent text-be-navy-blue-300"
                  onChange={updateTypeVal}
                  checked={typeVal === "corp_id" ? true : false}
                />
                <Label
                  htmlFor="corp_id"
                  className="text-be-navy-blue-700 font-semibold flex gap-1 items-center"
                >
                  <span className="hidden md:block">Corporation </span>ID
                </Label>
              </div>
              <div className="flex items-center gap-4">
                <Radio
                  id="corp_name"
                  name="type"
                  value="corp_name"
                  className="border border-gray-300 !ring-0 !ring-transparent focus:!ring-0 focus:!ring-transparent text-be-navy-blue-300"
                  onChange={updateTypeVal}
                  checked={typeVal === "corp_name" ? true : false}
                />
                <Label
                  htmlFor="corp_name"
                  className="text-be-navy-blue-700 font-semibold flex gap-1 items-center"
                >
                  <span className="hidden md:block">Corporation </span> Name
                </Label>
              </div>
              <div className="flex items-center gap-4">
                <Radio
                  id="ct_address"
                  name="type"
                  value="ct_address"
                  className="border border-gray-300 !ring-0 !ring-transparent focus:!ring-0 focus:!ring-transparent text-be-navy-blue-300"
                  onChange={updateTypeVal}
                  checked={typeVal === "ct_address" ? true : false}
                />
                <Label
                  htmlFor="ct_address"
                  className="text-be-navy-blue-700 font-semibold flex gap-1 items-center"
                >
                  <span className="hidden md:block">Contract </span> Address
                </Label>
              </div>
            </fieldset>
          </Flowbite>
          {isSearching && (
            <div className="mt-10">
              <Loader />
            </div>
          )}
          {searchResult === false && (
            <div className="my-6 flex flex-col gap-2 md:gap-4 items-center justify-center">
              <img src={info} className="w-12 h-12" alt="Info Icon" />
              <h2 className="text-xl font-bold text-be-navy-blue-700">
                No results found.
              </h2>
              <p className="text-gray-500 text-sm text-center max-w-96">
                {error}
              </p>
            </div>
          )}
          {searchResult && (
            <div className="border border-be-navy-blue-100 rounded-lg overflow-hidden">
              <div className="border-b border-be-navy-blue-100">
                <table className="table-fixed w-full">
                  <tbody className="divide-y divide-be-navy-blue-100">
                    <tr className="md:block flex flex-col">
                      <td className="font-medium text-be-navy-blue-500 px-4 md:px-6 pt-3 pb-1 md:py-3 md:w-56">
                        Public ID
                      </td>
                      <td className="px-4 md:px-6 font-bold text-blue-500 pt-1 pb-3 md:py-3">
                        {/** @ts-ignore **/}
                        {searchResult.corporationId}
                      </td>
                    </tr>
                    <tr className="md:block flex flex-col">
                      <td className="font-medium text-be-navy-blue-500 px-4 md:px-6 pt-3 pb-1 md:py-3 md:w-56">
                        Corporation Name
                      </td>
                      <td className="px-4 md:px-6 font-bold text-be-navy-blue-500 pt-1 pb-3 md:py-3">
                        {/** @ts-ignore **/}
                        {searchResult.name}
                      </td>
                    </tr>
                    <tr className="md:block flex flex-col">
                      <td className="font-medium text-be-navy-blue-500 px-4 md:px-6 pt-3 pb-1 md:py-3 md:w-56">
                        Website
                      </td>
                      <td className="px-4 md:px-6 font-bold text-blue-500 pt-1 pb-3 md:py-3">
                        {/** @ts-ignore **/}
                        {searchResult.url}
                      </td>
                    </tr>
                    <tr className="md:block flex flex-col">
                      <td className="font-medium text-be-navy-blue-500 px-4 md:px-6 pt-3 pb-1 md:py-3 md:w-56">
                        Number of shares
                      </td>
                      <td className="px-4 md:px-6 font-bold text-be-navy-blue-500 pt-1 pb-3 md:py-3">
                        {/** @ts-ignore **/}
                        {searchResult.tokenSupply}
                      </td>
                    </tr>
                    <tr className="md:block flex flex-col">
                      <td className="font-medium text-be-navy-blue-500 px-4 md:px-6 pt-3 pb-1 md:py-3 md:w-56">
                        Voting Delay
                      </td>
                      <td className="px-4 md:px-6 font-bold text-be-navy-blue-500 pt-1 pb-3 md:py-3">
                        {/** @ts-ignore **/}
                        {searchResult.votingDelay}
                      </td>
                    </tr>
                    <tr className="md:block flex flex-col">
                      <td className="font-medium text-be-navy-blue-500 px-4 md:px-6 pt-3 pb-1 md:py-3 md:w-56">
                        Voting Period
                      </td>
                      <td className="px-4 md:px-6 font-bold text-be-navy-blue-500 pt-1 pb-3 md:py-3">
                        {/** @ts-ignore **/}
                        {searchResult.votingPeriod}
                      </td>
                    </tr>
                    <tr className="md:block flex flex-col">
                      <td className="font-medium text-be-navy-blue-500 px-4 md:px-6 pt-3 pb-1 md:py-3 md:w-56">
                        Quorum
                      </td>
                      <td className="px-4 md:px-6 font-bold text-be-navy-blue-500 pt-1 pb-3 md:py-3">
                        {/** @ts-ignore **/}
                        {searchResult.quorum}
                      </td>
                    </tr>
                    <tr className="md:block flex flex-col">
                      <td className="font-medium text-be-navy-blue-500 px-4 md:px-6 pt-3 pb-1 md:py-3 md:w-56">
                        Contract Address
                      </td>
                      <td
                        className="px-4 md:px-6 font-bold text-blue-500 pt-1 pb-3 md:py-3"
                        style={{ lineBreak: "anywhere" }}
                      >
                        {/** @ts-ignore **/}
                        {searchResult.governorAddress}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="p-6">
                <Button
                  color="red"
                  label="Access DAO"
                  fullSized
                  onClick={() => {
                    callback();
                    // @ts-ignore
                    navigate("/dao/" + searchResult.governorAddress);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DAOSearchModal;
